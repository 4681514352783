import {
  APILoadingStatus,
  AdvancedMarker,
  Map,
  MapCameraChangedEvent,
  MapMouseEvent,
  MapProps,
  useApiLoadingStatus,
  useMap,
  useMapsLibrary,
} from '@vis.gl/react-google-maps';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { MapsContext } from '../../../App';
import { DEFAULT_MAP_CENTER, DEFAULT_MAP_ZOOM, ErrorMessages, Location, MAX_ZOOM, MIN_ZOOM } from '../../../shared';
import { isObjectEmpty } from '../../../utils';
import { HasSpinner, Notification } from '../../atoms';
import { Heatmap } from './heatmap';
import { PlaceAutocomplete } from './autocomplete';

export interface MapAddressProps {
  address: string;
  coords: Location;
}

interface HasMapProps extends MapProps {
  mapId: string;
  markerLocations?: Location[];
  heatmapLocations?: Location[];
  zoom?: number;
  width?: string;
  height?: string;
  address?: string;
  withAutocomplete?: boolean;
  autocompleteOnly?: boolean;
  handleAddress?: (mapAddress: MapAddressProps) => void;
}

const HasMap: React.FC<HasMapProps> = ({
  mapId,
  markerLocations,
  heatmapLocations,
  zoom,
  width,
  height,
  address,
  withAutocomplete = false,
  autocompleteOnly = false,
  handleAddress,
  ...props
}) => {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const initialCamera = {
    center: (props.center as Location) || DEFAULT_MAP_CENTER,
    zoom: zoom || DEFAULT_MAP_ZOOM,
    heading: 0,
    tilt: 0,
  };

  const status = useApiLoadingStatus();
  const geocodingLib = useMapsLibrary('geocoding');
  const map = useMap(mapId);

  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);
  const [addressCoordinates, setAddressCoordinates] = useState<Location>();
  const [cameraProps, setCameraProps] = useState(initialCamera);
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);

  useEffect(() => {
    if (geocodingLib) {
      const geocoderInstance = new geocodingLib.Geocoder();
      setGeocoder(geocoderInstance);
    }
  }, [geocodingLib]);

  useEffect(() => {
    if (status === APILoadingStatus.LOADED && address && geocoder) {
      const setCoords = async () => {
        const coords = await geocode(address);
        if (coords) {
          setAddressCoordinates(coords);
        }
      };
      setCoords();
    }
  }, [geocoder, status, address]);

  useEffect(() => {
    if (!map) return;
    if (heatmapLocations || markerLocations || addressCoordinates) {
      fitBounds();
    }
  }, [map, heatmapLocations, markerLocations, address, addressCoordinates]);

  useEffect(() => {
    if (!map || !selectedPlace) return;

    if (selectedPlace.geometry?.viewport) {
      map.fitBounds(selectedPlace.geometry?.viewport);
    }
  }, [selectedPlace]);

  const handleGeocodeResults = (
    results: google.maps.GeocoderResult[],
    status: google.maps.GeocoderStatus,
    isReverse: boolean
  ): Location | string | undefined => {
    switch (status) {
      case google.maps.GeocoderStatus.OK:
        if (isReverse) {
          return results[0].formatted_address;
        } else {
          if (results.every((result) => result.partial_match)) {
            Notification.warning(ErrorMessages.MAPS_PARTIAL_GEOCODER_RESULTS);
          }
          return { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
        }
      case google.maps.GeocoderStatus.ZERO_RESULTS:
      case google.maps.GeocoderStatus.INVALID_REQUEST:
        Notification.error(ErrorMessages.MAPS_INVALID_GEOCODER_ADDRESS);
        break;
      case google.maps.GeocoderStatus.OVER_QUERY_LIMIT:
      case google.maps.GeocoderStatus.REQUEST_DENIED:
      case google.maps.GeocoderStatus.UNKNOWN_ERROR:
      case google.maps.GeocoderStatus.ERROR:
        Notification.error(ErrorMessages.MAPS_GEOCODER_ERROR(''));
        break;
      default:
        break;
    }
  };

  const geocode = async (address: string): Promise<Location | undefined> => {
    return new Promise((resolve) => {
      if (geocoder) {
        geocoder.geocode({ address: address, region: 'GB' }, (results, status) => {
          results && resolve(handleGeocodeResults(results, status, false) as Location | undefined);
        });
      }
    });
  };

  const reverseGeocode = async (coords: Location): Promise<string | undefined> => {
    return new Promise((resolve) => {
      if (geocoder) {
        geocoder.geocode({ location: coords }, (results, status) => {
          results && resolve(handleGeocodeResults(results, status, true) as string | undefined);
        });
      }
    });
  };

  const handleMapClick = async (event: MapMouseEvent) => {
    if (handleAddress && event.detail.latLng) {
      const { lat, lng } = event.detail.latLng;
      const address = await reverseGeocode({ lat, lng });
      if (address) {
        handleAddress({ address, coords: event.detail.latLng });
      }
    }
  };

  const handleAutocomplete = async (place: google.maps.places.PlaceResult) => {
    if (handleAddress && place?.formatted_address) {
      setSelectedPlace(place);
      const coords = await geocode(place.formatted_address);
      if (coords) {
        handleAddress({ address: place.formatted_address, coords });
      }
    }
  };

  const fitBounds = () => {
    const bounds = new google.maps.LatLngBounds();
    if (bounds !== null && bounds !== undefined) {
      let shouldFit = false;
      if (heatmapLocations && heatmapLocations.length) {
        heatmapLocations.forEach((location) => bounds.extend(location));
        shouldFit = true;
      }
      if (markerLocations) {
        markerLocations.forEach((location) => bounds.extend(location));
        shouldFit = true;
      }
      if (addressCoordinates) {
        bounds.extend(addressCoordinates);
        shouldFit = true;
      }
      if (shouldFit) {
        map?.fitBounds(bounds);
      }
    }
  };

  const handleCameraChange = (e: any) => {
    setCameraProps(e.detail);
  };

  const buildMapMarkers = (): ReactNode[] | null => {
    if (markerLocations) {
      return markerLocations.map((location, index) => <AdvancedMarker key={index} position={location} />);
    } else {
      return null;
    }
  };

  const buildAddressMarker = (): ReactNode | null => {
    return !isObjectEmpty(addressCoordinates) ? <AdvancedMarker position={addressCoordinates} /> : null;
  };

  const getGoogleMapsLocations = (locations: Location[]): google.maps.LatLng[] =>
    locations.map((location) => new google.maps.LatLng(location));

  const buildHeatmapLayer = (): ReactNode | null => {
    if (heatmapLocations) {
      return <Heatmap locations={getGoogleMapsLocations(heatmapLocations)} radius={10} opacity={1} />;
    } else {
      return null;
    }
  };

  return (
    <MapsContext.Consumer>
      {(scriptLoaded) =>
        scriptLoaded ? (
          <div
            style={{
              height: height || '100%',
              width: width || '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {!autocompleteOnly && (
              <Map
                id={mapId}
                defaultCenter={cameraProps.center as google.maps.LatLngLiteral}
                defaultZoom={cameraProps.zoom}
                mapId={API_KEY}
                minZoom={MIN_ZOOM}
                maxZoom={MAX_ZOOM}
                style={{
                  width: width || '100%',
                  height: height || '100%',
                }}
                onCameraChanged={handleCameraChange}
                onClick={handleMapClick}
              >
                {buildMapMarkers()}
                {buildAddressMarker()}
                {scriptLoaded && buildHeatmapLayer()}
              </Map>
            )}
            {withAutocomplete && <PlaceAutocomplete onPlaceSelect={handleAutocomplete} defaultAddress={address} />}
          </div>
        ) : (
          <HasSpinner size="large" />
        )
      }
    </MapsContext.Consumer>
  );
};

export { HasMap };
