import React from 'react';
import { HasButton, HasMap, HasParagraph, HasRadioGroup, MapAddressProps, Notification } from '../../..';
import i18n from '../../../../i18n/config';
import {
  DEFAULT_MAP_CENTER,
  ErrorMessages,
  Location,
  RadioGroupOption,
  TypedRadioGroupProps,
} from '../../../../shared';
import { HasText } from '../../../atoms';
import { RadioChangeEvent } from 'antd/lib/radio';
import { LocationType, LocationTypeLabel } from '../../../../models';
import { i18nLabelParser } from '../../../../utils';
import { isUndefined } from 'lodash';

interface LocationRadioGroupProps {
  onChange: (data?: any) => void;
  onSave: (data: any) => void;
  closeEditModeCallback?: any;
}

interface LocationRadioGroupState {
  error: boolean;
  locationTypeRadioOptionsConfig: RadioGroupOption<string>[];
  selectedLocationType: LocationType | undefined;
  address: string | undefined;
  addressCoordinates: Location;
}

class HasLocationRadioGroup extends React.Component<LocationRadioGroupProps, LocationRadioGroupState> {
  private locationTypeRadioConfig: TypedRadioGroupProps<string>;

  constructor(props: Readonly<LocationRadioGroupProps>) {
    super(props);

    this.locationTypeRadioConfig = {
      size: 'small',
      buttonStyle: 'solid',
      onChange: this.onLocationTypeChange,
    };

    const locationTypeRadioOptionsConfig = this.buildRadioOptions();

    this.state = {
      error: false,
      locationTypeRadioOptionsConfig,
      selectedLocationType: undefined,
      address: '',
      addressCoordinates: DEFAULT_MAP_CENTER,
    };
  }

  componentDidMount() {
    i18n.on('languageChanged', () =>
      this.setState({
        locationTypeRadioOptionsConfig: this.buildRadioOptions(),
      })
    );
  }

  buildRadioOptions = (): RadioGroupOption<string>[] => {
    return Object.values(LocationType).map((value, index) => ({
      key: index,
      value: value,
      forObject: index,
      label: i18nLabelParser(LocationTypeLabel[value]),
    }));
  };

  onLocationTypeChange = (event: RadioChangeEvent) => {
    this.setState({ selectedLocationType: event.target.value });
    this.props.onChange(event.target.value);
  };

  saveData = () => {
    const { selectedLocationType, address, addressCoordinates } = this.state;
    if (isUndefined(selectedLocationType) || isUndefined(addressCoordinates) || address === '') {
      this.setState({ error: true });
    } else {
      this.props.onSave({ selectedLocationType: LocationTypeLabel[selectedLocationType], address, addressCoordinates });
      this.props.closeEditModeCallback && this.props.closeEditModeCallback();
    }
  };

  handleAddress = (mapAddress: MapAddressProps) => {
    this.setState({
      addressCoordinates: mapAddress.coords,
      address: mapAddress.address,
    });
  };

  componentWillUnmount() {
    i18n.on('languageChanged', () =>
      this.setState({
        locationTypeRadioOptionsConfig: this.buildRadioOptions(),
      })
    );
  }

  render() {
    const { address, addressCoordinates, error, locationTypeRadioOptionsConfig, selectedLocationType } = this.state;
    console.log('ce', selectedLocationType);
    return (
      <div className="mt-2">
        <HasParagraph content={i18n.t('investigation.pickOne')} style={{ fontSize: '12px', marginBottom: '8px' }} />
        <HasRadioGroup {...this.locationTypeRadioConfig} groupOptions={locationTypeRadioOptionsConfig} />
        <div className="d-flex flex-row justify-content-between">
          {selectedLocationType === LocationType.MAP && (
            <div style={{ height: '15rem' }} className="flex-grow-1 mr-4">
              <HasMap
                mapId="event-edit-location-map"
                markerLocations={[addressCoordinates]}
                handleAddress={this.handleAddress}
                withAutocomplete
              />
            </div>
          )}
          {selectedLocationType === LocationType.ADDRESS && (
            <div className="flex-grow-1 mr-4">
              <HasMap
                mapId="event-edit-location-address"
                handleAddress={this.handleAddress}
                withAutocomplete
                autocompleteOnly
              />
            </div>
          )}
          {!isUndefined(selectedLocationType) && (
            <HasButton type="primary" size="small" htmlType="button" onClick={this.saveData} className="align-self-end">
              {i18n.t('shared.save')}
            </HasButton>
          )}
        </div>
        {error && (
          <HasText
            content={ErrorMessages.MAPS_INVALID_GEOCODER_ADDRESS('').message}
            type="danger"
            style={{ fontSize: '12px' }}
          />
        )}
      </div>
    );
  }
}

export default HasLocationRadioGroup;
