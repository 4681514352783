import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { isEqual } from 'lodash';
import React from 'react';
import { HasMap, MapAddressProps } from '..';
import i18n from '../../../i18n/config';
import { Company } from '../../../models';
import { ErrorMessages, ModalBaseProps } from '../../../shared';
import { HasModal, HasTextInput, Notification } from '../../atoms';

interface CompanyModalProps extends ModalBaseProps<Company> {
  company: Company;
}

interface CompanyModalState {
  updateInProgress: boolean;
  tmpCompanyAddress: string | undefined;
}

class HasEditCompanyModal extends React.Component<CompanyModalProps, CompanyModalState> {
  state = { updateInProgress: false, tmpCompanyAddress: this.props.company.address };

  private formRef = React.createRef<FormInstance>();

  componentDidUpdate(prevProps: Readonly<CompanyModalProps>) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({ updateInProgress: false, tmpCompanyAddress: this.props.company.address });
    }
  }

  handlePlaceChanged = (mapAddress: MapAddressProps) => {
    this.formRef.current?.setFieldsValue({ address: mapAddress.address });
    this.setState({ tmpCompanyAddress: mapAddress.address });
  };

  onCancel = () => {
    this.formRef.current?.resetFields();
    this.props.onCancel();
  };

  onOk = (values: any) => {
    this.setState({ updateInProgress: true });
    const company: Company = { ...this.props.company, ...values };
    delete company.divisions;
    this.props.onOk(company).then(
      () => {
        this.setState({ updateInProgress: false });
        this.formRef.current?.resetFields();
      },
      () => this.setState({ updateInProgress: false })
    );
  };

  render() {
    const { company, visible } = this.props;
    const { tmpCompanyAddress } = this.state;
    return (
      <HasModal
        title={i18n.t('company.editCompany')}
        okText={i18n.t('shared.save')}
        cancelText={i18n.t('shared.cancel')}
        onCancel={this.onCancel}
        closable={false}
        visible={visible}
        okButtonProps={{ loading: this.state.updateInProgress, htmlType: 'submit', form: 'company-form' }}
        cancelButtonProps={{ disabled: this.state.updateInProgress }}
        destroyOnClose
        width={720}
      >
        <Row gutter={20} style={{ padding: '0px 60px' }}>
          <Col span={8}>
            <Form
              id="company-form"
              ref={this.formRef}
              onFinish={this.onOk}
              onFinishFailed={() =>
                Notification.error(ErrorMessages.INCOMPLETE_FORM_FOR_ENTITY(i18n.t('shared.company').toLowerCase()))
              }
              hideRequiredMark
              layout="vertical"
              initialValues={{ name: company.name, phoneNumber: company.phoneNumber, address: company.address }}
            >
              <Form.Item
                name="name"
                label={i18n.t('company.companyName')}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: ErrorMessages.INPUT_REQUIRED(i18n.t('company.companyName').toLowerCase()),
                  },
                  {
                    whitespace: true,
                    message: ErrorMessages.INPUT_REQUIRED(i18n.t('company.companyName').toLowerCase()),
                  },
                ]}
              >
                <HasTextInput key="name" size="large" />
              </Form.Item>
              <Form.Item
                name="phoneNumber"
                label={i18n.t('shared.phone')}
                colon={false}
                rules={[
                  { required: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.phoneNumber').toLowerCase()) },
                  {
                    whitespace: true,
                    message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.phoneNumber').toLowerCase()),
                  },
                ]}
              >
                <HasTextInput key="phoneNumber" size="large" />
              </Form.Item>
              <Form.Item
                name="address"
                label={i18n.t('shared.address')}
                colon={false}
                rules={[
                  { required: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.address').toLowerCase()) },
                  { whitespace: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.address').toLowerCase()) },
                ]}
              >
                <HasMap
                  mapId="company-modal-form-item"
                  withAutocomplete
                  autocompleteOnly
                  handleAddress={this.handlePlaceChanged}
                  address={company.address}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col span={16}>
            <HasMap mapId="company-modal" address={tmpCompanyAddress} />
          </Col>
        </Row>
      </HasModal>
    );
  }
}

export default HasEditCompanyModal;
