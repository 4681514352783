import { RcFile } from 'antd/lib/upload';
import { cloneDeep } from 'lodash';
import React, { ReactText } from 'react';
import { ThemeProvider } from 'styled-components';
import { HasChatBot, HasChatBotDropdown } from '.';
import i18n from '../../../i18n/config';
import { Company, Division, Form, FormResultType, NewFormResult, NewIncident, UserRole } from '../../../models';
import { DivisionService, IncidentService } from '../../../services';
import {
  ChatBotRenderedStep,
  ChatBotResult,
  ChatBotStep,
  ChatBotStepValue,
  CHAT_BOT_ADDRESS,
  CHAT_BOT_DATETIME,
  CHAT_BOT_IMAGE_PICKER,
  CHAT_BOT_MAPS,
  CHAT_BOT_ORG_SELECTION,
  CHAT_BOT_THEME,
  COLORS,
  CustomStep,
  LocationWithAddress,
  OptionsStep,
  ORG_SELECTION,
  StepOption,
  TextStep,
  UserStep,
  FORM_RESULT_KEYS,
  MISSING_NAME_PLACEHOLDER,
} from '../../../shared';
import { HasSpinner } from '../../atoms';
import HasChatBotAddress from './chatbot.address';
import HasChatBotDatePicker from './chatbot.datepicker';
import HasChatBotImagePicker from './chatbot.imagepicker';
import HasChatBotMap from './chatbot.map';
import { getUserRole, isLimitedEmployeePersonal } from '../../../utils';
import moment from 'moment';

interface ChatBotWrapperProps {
  form: Form;
  doneCallback: (error?: string) => void;
  forCompany?: Company;
  forDivision?: Division;
}

interface ChatBotWrapperState {
  key: number;
  loading: boolean;
  userDivisions: Division[];
}

class HasChatBotWrapper extends React.Component<ChatBotWrapperProps, ChatBotWrapperState> {
  state: ChatBotWrapperState = { key: 0, loading: true, userDivisions: [] };

  async componentDidMount() {
    const { forCompany } = this.props;
    let divisions: Division[] = [];
    if (getUserRole() === UserRole.LIMITED_EMPLOYEE_DIVISION || isLimitedEmployeePersonal()) {
      const { data } = await DivisionService.getLoggedInUserDivision();
      divisions = data;
    } else if (forCompany) {
      divisions = forCompany.divisions;
    } else {
      const { data } = await DivisionService.getDivisionForLoggedInUser();
      divisions = data;
    }
    this.setState({ userDivisions: divisions, loading: false });
  }

  componentDidUpdate(prevProps: Readonly<ChatBotWrapperProps>) {
    if (prevProps.form.id !== this.props.form.id) {
      this.setState({ key: this.state.key + 1, loading: false });
    }
  }

  setStepi18nLabel = (step: ChatBotStep) => {
    if ('message' in step) {
      const message = (step as TextStep).message;
      if (typeof message === 'string') {
        (step as TextStep).message = i18n.t(message);
      }
    }
  };

  setStepi18nOptionsLabels = (step: ChatBotStep) => {
    if ('options' in step) {
      let options = (step as OptionsStep).options;
      options = options.map((option) => ({ ...option, label: i18n.t(option.label) }));
      (step as OptionsStep).options = options;
    }
  };

  getDivisionOptions = (divisions: Division[]) => {
    return divisions.map((div: Division) => {
      return {
        key: div.id,
        value: div.name,
        label: div.name,
        forObject: div,
        className: div.name === MISSING_NAME_PLACEHOLDER ? 'italic-text' : '',
      };
    });
  };

  getChatBotSteps = (): ChatBotStep[] => {
    const formCopy = cloneDeep(this.props.form);
    const { userDivisions } = this.state;
    const steps: ChatBotStep[] = formCopy.mappedSteps.map<ChatBotStep>((step) => {
      this.setStepi18nLabel(step);
      this.setStepi18nOptionsLabels(step);
      switch (step.id) {
        case CHAT_BOT_DATETIME:
          (step as CustomStep).component = <HasChatBotDatePicker />;
          break;
        case CHAT_BOT_ADDRESS:
          const { trigger, metadata } = step as UserStep;
          delete (step as UserStep).user;
          delete (step as UserStep).trigger;
          delete (step as UserStep).metadata;
          (step as CustomStep).metadata = { ...metadata, label: 'shared.address', trigger: trigger };
          (step as CustomStep).component = <HasChatBotAddress />;
          break;
        case CHAT_BOT_ORG_SELECTION:
          const { metadata: orgSelectionMetadata } = step;
          delete orgSelectionMetadata.options;
          delete (step as any).options;
          (step as CustomStep).metadata = { ...orgSelectionMetadata, trigger: 'whereDidItHappenQuestion' };
          (step as CustomStep).component = (
            <HasChatBotDropdown<Division>
              options={this.getDivisionOptions(userDivisions)}
              placeholder={i18n.t('report.selectDivision')}
            />
          );
          break;
        case CHAT_BOT_MAPS:
          (step as CustomStep).component = <HasChatBotMap />;
          break;
        case CHAT_BOT_IMAGE_PICKER:
          (step as CustomStep).component = <HasChatBotImagePicker />;
          break;
      }
      return step;
    });
    return this.addReview(steps);
  };

  addReview = (steps: ChatBotStep[]) => {
    const firstId: string = steps[0].id;

    const optionsId: string = 'reviewOptions';
    const newEndId: string = 'reviewEnd';

    const endStep = steps.pop();
    const oldEndId: string = endStep!.id;
    endStep!.id = newEndId;

    const question: TextStep = {
      id: oldEndId,
      message: i18n.t('chatbot.reviewQuestion'),
      trigger: optionsId,
    };

    const review: StepOption = {
      value: true,
      label: i18n.t('shared.yes'),
      trigger: firstId,
      reset: true,
    };

    const done: StepOption = {
      value: false,
      label: i18n.t('shared.no'),
      trigger: newEndId,
    };

    const options: OptionsStep = {
      id: optionsId,
      options: [review, done],
    };

    return [...steps, question, options, endStep!];
  };

  hasExtraValue = (step: ChatBotRenderedStep, value: ChatBotStepValue): boolean => {
    const {
      metadata: { optionValuesWithDescription },
    } = step;
    return optionValuesWithDescription
      ? (optionValuesWithDescription as any)
          .map((item: boolean | ReactText) => item.toString())
          .includes(value.toString())
      : false;
  };

  getStepValue = (value: ChatBotStepValue) => {
    switch (typeof value) {
      case 'object':
        return (value as LocationWithAddress).address;
      default:
        return value;
    }
  };

  generateSimpleFormResult = (step: ChatBotRenderedStep, value: ChatBotStepValue): NewFormResult => {
    let formResult: NewFormResult = {
      key: step.metadata.key,
      type: step.metadata.type,
      seqNo: 0,
      createdAt: moment().toISOString(),
      value,
    };
    return formResult;
  };

  handleEnd = (chatBotResult: ChatBotResult) => {
    const { form, doneCallback, forCompany } = this.props;

    this.setState({ loading: true });

    let formResults: NewFormResult[] = [];
    let incident: NewIncident = {
      formResult: formResults,
      type: form.type,
    };
    let incidentPhotos: RcFile[] = [];

    const values: Record<string, ChatBotStepValue> = chatBotResult.finalAnswers;
    chatBotResult.finalSteps.forEach((step, index) => {
      switch (step.id) {
        case CHAT_BOT_MAPS:
          const locationWithMapAddress = values[step.id] as LocationWithAddress;
          incident = { ...incident, ...locationWithMapAddress.coords };
          break;
        case CHAT_BOT_ADDRESS:
          const locationWithAddress = values[step.id] as LocationWithAddress;
          incident = { ...incident, ...locationWithAddress.coords };
          break;
        case CHAT_BOT_IMAGE_PICKER:
          incidentPhotos = values[step.id] as RcFile[];
          break;
        default:
          if (step.metadata) {
            let formResult = this.generateSimpleFormResult(step, values[step.id]);
            if (
              FormResultType.OPTION_DESCRIPTION === step.metadata.type &&
              (this.hasExtraValue(step, values[step.id]) || formResult.key === FORM_RESULT_KEYS.WHERE_DID_IT_HAPPEN)
            ) {
              const id = chatBotResult.finalSteps[index + 1].id;
              formResult.extra = this.getStepValue(values[id]);
            }
            formResults.push(formResult);
          }
          break;
      }
    });

    incident.formResult = formResults;

    if (forCompany) {
      incident.company = forCompany;
      const orgSelectionFormResult = incident.formResult.find((result) => result.key === ORG_SELECTION);
      if (orgSelectionFormResult) {
        incident.division = forCompany.divisions.find(
          (division) => division.name === orgSelectionFormResult.value || division.id === orgSelectionFormResult.value
        );
      }
    }

    let formData = new FormData();
    formData.append('incident', new Blob([JSON.stringify(incident)], { type: 'application/json' }));
    incidentPhotos.map((photo) => photo as File).forEach((photo) => formData.append('files', photo));

    const responseCallback = (error?: any) => {
      doneCallback(error);
    };

    IncidentService.createNewIncident(formData)
      .then(
        () => responseCallback(),
        (error) => responseCallback(error)
      )
      .catch((error) => responseCallback(error));
  };

  render() {
    const { key, loading } = this.state;
    return loading ? (
      <HasSpinner size="large" />
    ) : (
      <ThemeProvider theme={CHAT_BOT_THEME}>
        <HasChatBot
          key={key}
          steps={this.getChatBotSteps()}
          handleEnd={this.handleEnd}
          botDelay={0}
          userDelay={0}
          customDelay={0}
          placeholder={i18n.t('chatbot.inputPlaceholder')}
          inputStyle={{ borderRadius: 0, fontSize: '12px' }}
          bubbleOptionStyle={{ fontSize: '12px', backgroundColor: COLORS.GREEN_DARK }}
          bubbleOptionSelectedStyle={{ fontSize: '12px', backgroundColor: COLORS.NEW_ORANGE }}
          bubbleStyle={{ fontSize: '12px' }}
          contentStyle={{ height: 'calc(100% - 51px)', fontSize: '12px', overflowY: 'auto' }}
          customStyle={{
            backgroundColor: 'transparent',
            borderWidth: 0,
            boxShadow: 'none',
            padding: 0,
            margin: 0,
            justifyContent: 'flex-end',
          }}
          style={{ height: '100%', width: '100%', borderRadius: 0, boxShadow: 'none' }}
          className="h-100 w-100"
          hideHeader
          hideBotAvatar
          hideUserAvatar
        />
      </ThemeProvider>
    );
  }
}

export default HasChatBotWrapper;
