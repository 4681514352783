import React from 'react';
import { MdCheck } from 'react-icons/md';
import { HasButton, HasMap, HasText, HasTextInput, MapAddressProps } from '../..';
import { MapsContext } from '../../../App';
import { ChatBotCustomComponentProps, Location, COLORS } from '../../../shared';
import i18n from '../../../i18n/config';

interface ChatBotAddressState {
  canChangeLocation: boolean;
  locationSelected: boolean;
  addressCoordinates: Location;
  address: string | undefined;
}

class HasChatBotAddress extends React.Component<ChatBotCustomComponentProps, ChatBotAddressState> {
  state = {
    canChangeLocation: true,
    locationSelected: this.props.value,
    addressCoordinates: this.props.value?.coords || ({} as Location),
    address: this.props.value?.address || '',
  };

  handlePlaceChanged = (mapAddress: MapAddressProps) => {
    this.setState({
      address: mapAddress.address,
      addressCoordinates: mapAddress.coords,
      locationSelected: true,
    });
  };

  saveLocation = () => {
    const { step, triggerNextStep } = this.props;
    const { addressCoordinates, address } = this.state;
    this.setState({ canChangeLocation: false });
    if (step && triggerNextStep) {
      triggerNextStep({ value: { coords: addressCoordinates, address: address }, trigger: step.metadata.trigger });
    }
  };

  render() {
    const { canChangeLocation, address, locationSelected } = this.state;
    return (
      <MapsContext.Consumer>
        {(scriptLoaded) =>
          scriptLoaded && (
            <div
              style={{ backgroundColor: canChangeLocation ? COLORS.GRAY_LIGHT : COLORS.PRIMARY_BLUE }}
              className="d-flex has-chat-bot-component"
            >
              {canChangeLocation ? (
                <React.Fragment>
                  <HasMap
                    mapId="chatbot-address"
                    withAutocomplete
                    autocompleteOnly
                    handleAddress={this.handlePlaceChanged}
                  />
                  <div className="d-flex">
                    {canChangeLocation && locationSelected && (
                      <HasButton
                        type="link"
                        onClick={this.saveLocation}
                        icon={<MdCheck style={{ color: COLORS.SECONDARY_GREEN, fontSize: '20px' }} />}
                        style={{ minWidth: 0, alignSelf: 'center' }}
                      />
                    )}
                  </div>
                </React.Fragment>
              ) : (
                <HasText content={address} style={{ color: COLORS.WHITE, fontSize: '12px', fontWeight: 500 }} />
              )}
            </div>
          )
        }
      </MapsContext.Consumer>
    );
  }
}

export default HasChatBotAddress;
