import { Col, Row } from 'antd';
import { TFunction } from 'i18next';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AiOutlineMail } from 'react-icons/ai';
import { FaRegBuilding } from 'react-icons/fa';
import { FiMapPin } from 'react-icons/fi';
import { IoIosArrowBack } from 'react-icons/io';
import { CSSProperties } from 'styled-components';
import { history } from '../..';
import { HasButton, HasMap, HasParagraph, HasText, HasTitle } from '../../components';
import { isTokenExpired } from '../../utils';

type ContactInfo = {
  name: string;
  companyNumber: string;
  address: string;
  phoneNumber?: string;
  emailAddresses: { label: string; address: string }[];
};

const getContactInfo = (t: TFunction): ContactInfo => ({
  name: 'HIGHVIZZ LIMITED',
  companyNumber: '12628317',
  address: '56 Queen Anne Street, London, England, W1G 8LA',
  emailAddresses: [
    { label: t('shared.contact'), address: 'contact@highvizz.com' },
    { label: t('contact.support'), address: 'support@highvizz.com' },
  ],
});

class HasContactPage extends React.Component<WithTranslation, {}> {
  iconsStyle: CSSProperties = { fontSize: '24px', verticalAlign: 'middle', marginRight: '5px', flexShrink: 0 };

  render() {
    const { t } = this.props;
    const showFullScreen = !isTokenExpired();
    const CONTACT_INFO = getContactInfo(t);
    return (
      <div className="has-contact-page">
        <div
          style={{ width: showFullScreen ? '100%' : '70%', height: showFullScreen ? '100%' : 'auto' }}
          className={'has-contact-container has-container-tile overflow-y-auto'.concat(
            showFullScreen ? ' d-flex flex-grow-1' : ''
          )}
        >
          <Row className="flex-grow-1">
            <Col xs={24} sm={24} md={24} lg={8} xl={8} className="has-contact-details">
              <div className="d-flex flex-row align-items-center pb-4">
                <IoIosArrowBack
                  onClick={() => history.goBack()}
                  style={{
                    cursor: 'pointer',
                    fontSize: '18px',
                    marginRight: '10px',
                  }}
                />
                <HasTitle content={t('shared.contactInfo')} level={4} className="mb-0" />
              </div>
              <HasParagraph
                content={
                  <div className="d-flex flex-row align-items-center">
                    <FaRegBuilding style={this.iconsStyle} />
                    <div className="d-flex flex-column flex-grow-1 ml-4">
                      <Row className="flex-column" style={{ marginRight: '20px', justifyContent: 'center' }}>
                        <HasText content={CONTACT_INFO.name} strong />
                      </Row>
                      <Row className="flex-column" style={{ marginTop: '2px' }}>
                        <Col>
                          <HasText content={t('contact.companyNumber')} />
                        </Col>
                        <Col flex={'auto'}>
                          <HasText content={CONTACT_INFO.companyNumber} strong />
                        </Col>
                      </Row>
                    </div>
                  </div>
                }
                className="py-2"
              />
              <HasParagraph
                content={
                  <div className="d-flex flex-row align-items-center">
                    <FiMapPin style={this.iconsStyle} />
                    <div className="d-flex flex-grow-1 ml-4">
                      <Row className="flex-column">
                        <Col>
                          <HasText content={t('shared.address')} />
                        </Col>
                        <Col>
                          <HasText content={CONTACT_INFO.address} strong />
                        </Col>
                      </Row>
                    </div>
                  </div>
                }
                className="py-2"
              />
              {CONTACT_INFO.emailAddresses.map((addressDetails, index) => (
                <HasParagraph
                  key={index}
                  content={
                    <div className="d-flex flex-row align-items-center">
                      <AiOutlineMail style={this.iconsStyle} />
                      <div className="d-flex flex-grow-1 ml-4">
                        <Row className="flex-column">
                          <Col>
                            <HasText content={addressDetails.label} />
                          </Col>
                          <Col>
                            <HasButton
                              type="link"
                              href={`mailto:${addressDetails.address}`}
                              style={{ fontWeight: 600, padding: 0 }}
                            >
                              {addressDetails.address}
                            </HasButton>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  }
                  className="py-2"
                />
              ))}
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} className="has-contact-map">
              <HasMap mapId="contact" address={CONTACT_INFO.address} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withTranslation()(HasContactPage);
