import { EyeInvisibleOutlined, EyeOutlined, RightOutlined } from '@ant-design/icons';
import { Badge, DatePicker, Tag, Tooltip } from 'antd';
import { ColumnProps, ColumnsType } from 'antd/es/table';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { PaginationConfig } from 'antd/lib/pagination';
import { ColumnFilterItem, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { compact, concat, isArray, isEmpty, isEqual, isUndefined } from 'lodash';
import React, { ReactText } from 'react';
import Highlighter from 'react-highlight-words';
import { AiOutlineCheckSquare, AiOutlineFileSearch, AiOutlineWarning, AiOutlineInfoCircle } from 'react-icons/ai';
import { FaArrowRight } from 'react-icons/fa';
import { Subscription } from 'rxjs';
import i18n from '../../../i18n/config';
import {
  HAS_ACTIONS,
  Incident,
  IncidentKey,
  IncidentSeverity,
  IncidentSubType,
  IncidentSubTypeLabel,
  IncidentType,
  IncidentTypeLabel,
  InvestigationStatus,
  IS_INVESTIGATION_REQUIRED,
  REPORTED_AT,
  SIF,
  STATUS,
  SUB_TYPE,
  TYPE,
} from '../../../models';
import { CompanyService, DivisionService, FiltersService, IncidentService } from '../../../services';
import {
  COLOR_RISK_LEVEL,
  COLORS,
  DATE_LOCALE,
  DB_DATE_FORMAT,
  ErrorMessages,
  EventFilters,
  InvestigationStatusLabel,
  oneDayInMs,
  SORT_DIRECTIONS,
  SuccessMessages,
} from '../../../shared';
import {
  ACTION_KEYS,
  catchClickEvent,
  EventUtils,
  formatTextToTitlecase,
  getDate,
  getHighlighterProps,
  getTime,
  getUserFullName,
  getUserId,
  isLimitedEmployeePersonal,
  TABLE_HEADER_HEIGHT,
  TABLE_ITEM_HEIGHT,
  TABLE_PAGE_SIZE,
  TABLE_PAGINATION_HEIGHT,
} from '../../../utils';
import { HasButton, HasCheckbox, HasSearchInput, HasText, Notification } from '../../atoms';
import { HasTable, RiskScoreTag } from '../../molecules';
import moment, { Moment } from 'moment';

interface IncidentTableProps {
  navigateToIncident: (incidentId: number) => void;
  navigateToAction: (incidentId: number) => void;
  navigateToInvestigation: (incidentId: number) => void;
  exportCsvCallback: () => void;
  urlQueryParam?: string;
}

interface IncidentTableState {
  data: Incident[];
  searchTerm: string;
  tableData: Incident[];
  filteredData: Incident[];
  selectedData: Incident[];
  startDownload: boolean;
  total?: number;
  loading: boolean;
  dateRange: [Moment | null, Moment | null] | null;
}

const INCIDENT_KEYS: Array<IncidentKey> = [TYPE, SUB_TYPE, REPORTED_AT, IS_INVESTIGATION_REQUIRED, STATUS, HAS_ACTIONS];

class HasIncidentsTable extends React.Component<IncidentTableProps, IncidentTableState> {
  state: IncidentTableState = {
    searchTerm: '',
    data: [] as Incident[],
    tableData: [],
    filteredData: [],
    selectedData: [],
    startDownload: false,
    loading: false,
    dateRange: null,
  };

  private subscriptions: Subscription[] = [];

  private incidentTypeFilters: ColumnFilterItem[] = [];
  private incidentSubTypeFilters: ColumnFilterItem[] = [];
  private investigationFilters: ColumnFilterItem[] = [];
  private statusFilters: ColumnFilterItem[] = [];
  private actionFilters: ColumnFilterItem[] = [];
  private companyFilters: ColumnFilterItem[] = [];
  private sifFilters: ColumnFilterItem[] = [];
  private filters: EventFilters;
  private dataLoadedOnCompanySubscribe = false;

  constructor(props: Readonly<IncidentTableProps>) {
    super(props);
    this.setTableFilters();
    this.filters = { ...this.getFiltersValues() };
  }

  setTableFilters = () => {
    let incidentTypes = Object.values(IncidentType);
    this.incidentTypeFilters = incidentTypes.map((type, index) => ({
      text: i18n.t(IncidentTypeLabel[type]),
      value: index,
    }));

    let incidentSubTypes = Object.values(IncidentSubType);
    this.incidentSubTypeFilters = incidentSubTypes.map((subType, index) => ({
      text: i18n.t(IncidentSubTypeLabel[subType]),
      value: IncidentSubTypeLabel[subType],
    }));

    let statusTypes = Object.values(InvestigationStatus);
    this.statusFilters = statusTypes.map((status, index) => ({
      text: i18n.t(InvestigationStatusLabel[status]),
      value: index,
    }));

    this.investigationFilters = [
      { text: i18n.t('shared.yes'), value: 1 },
      { text: i18n.t('shared.no'), value: 0 },
    ];
    this.actionFilters = [
      { text: i18n.t('shared.yes'), value: 1 },
      { text: i18n.t('shared.no'), value: 0 },
    ];

    this.sifFilters = [
      { text: i18n.t('incidents.highPotentialEvent'), value: 1 },
      { text: i18n.t('incidents.nonHighPotentialEvent'), value: 0 },
    ];
  };

  componentDidMount() {
    this.subscriptions.push(
      CompanyService.getCompanyListener().subscribe((companyId) => {
        this.getIncidentCountAndList();
        this.dataLoadedOnCompanySubscribe = true;
      }),
      DivisionService.getDivisionListener().subscribe((divisionId) => {
        this.dataLoadedOnCompanySubscribe
          ? (this.dataLoadedOnCompanySubscribe = false)
          : this.getIncidentCountAndList();
      })
    );

    i18n.on('languageChanged', () => this.setTableFilters());
  }

  getIncidentCountAndList = () => {
    this.getIncidentsList();
  };

  getIncidentsList = () => {
    this.setState({ loading: true });
    IncidentService.getAllPaginatedAndFiltered(this.filters)
      .then((data) => {
        this.setState({ data: data.data.content, total: data.data.totalElements, loading: false });
      })
      .catch((error) => Notification.error({ message: error.response.data.message }));
  };

  getFiltersValues = (): EventFilters => {
    const { urlQueryParam } = this.props;
    let filters = FiltersService.getEventTableFilters();
    if (!filters?.columns) {
      const defaultFiltersState = {
        columns: {},
        showHidden: false,
        pagination: { current: 1 },
      };
      filters = defaultFiltersState;
    }
    if (urlQueryParam) {
      filters.columns[TYPE] = this.getFilterAndSorterStatus(TYPE, [urlQueryParam]);
    }
    INCIDENT_KEYS.forEach((key) => {
      if ((key !== TYPE || !urlQueryParam) && !filters?.columns[key]) {
        filters!.columns[key] = this.getFilterAndSorterStatus(key);
      }
    });
    filters.pageSize = TABLE_PAGE_SIZE;
    if (isLimitedEmployeePersonal()) {
      filters.loggedInUserId = getUserId() || undefined;
    }
    filters.columns[REPORTED_AT].defaultSortOrder = SORT_DIRECTIONS.DESCEND[0];

    FiltersService.saveEventTableFilters(filters);
    return filters;
  };

  getFilterAndSorterStatus = (
    field: keyof Incident,
    otherFilterValues?: ReactText[]
  ): Partial<Pick<ColumnProps<Incident>, 'defaultSortOrder' | 'defaultFilteredValue'>> => {
    const columnFilters = this.filters?.columns[field];
    if (!isUndefined(columnFilters)) {
      const { defaultSortOrder, defaultFilteredValue } = columnFilters;
      const filterValues = compact(concat(defaultFilteredValue, otherFilterValues));
      return {
        defaultSortOrder,
        defaultFilteredValue: !isEmpty(filterValues) ? filterValues : undefined,
      };
    } else {
      return otherFilterValues ? { defaultFilteredValue: otherFilterValues } : {};
    }
  };

  tableSorterValueChanged = (sorter: SorterResult<Incident>) => {
    // This function is designed only for single column sorting!
    if (!isUndefined(sorter.columnKey)) {
      const { columnKey, order } = sorter;
      let columnData = this.filters.columns[columnKey];
      columnData = { ...columnData, defaultSortOrder: order };
      this.filters = {
        ...this.filters,
        columns: { ...this.filters.columns, [columnKey]: columnData },
      };

      const columnKeys = Object.keys(this.filters.columns).filter((key) => !isEqual(key, columnKey));
      columnKeys.forEach((key) => {
        let columnData = this.filters.columns[key];
        columnData = { ...columnData, defaultSortOrder: undefined };
        this.filters = {
          ...this.filters,
          columns: { ...this.filters.columns, [key]: columnData },
        };
      });
    }
  };

  tableFilterValueChanged = (filters: Record<string, ReactText[] | null>) => {
    const entries = Object.entries(filters);
    entries.forEach((entry) => {
      let columnData = this.filters.columns[entry[0]];
      columnData = { ...columnData, defaultFilteredValue: entry[1] };
      this.filters = {
        ...this.filters,
        columns: { ...this.filters.columns, [entry[0]]: columnData },
      };
    });
  };

  onTableChange = (
    pagination: PaginationConfig,
    filters: Record<string, ReactText[] | null>,
    sorter: SorterResult<Incident> | SorterResult<Incident>[],
    extra: TableCurrentDataSource<Incident>
  ) => {
    this.filters.pageNumber = pagination.current;
    if (!isArray(sorter)) {
      this.tableSorterValueChanged(sorter);
    }
    this.tableFilterValueChanged(filters);
    FiltersService.saveEventTableFilters(this.filters);
    this.getIncidentCountAndList();
  };

  getSeverityBadge = (severity: IncidentSeverity) => {
    if (severity) {
      const stringKey = severity === IncidentSeverity.SEVERE ? 'incidents.severe' : 'incidents.moderate';
      const color = severity === IncidentSeverity.SEVERE ? 'red' : 'yellow';
      return (
        <Tooltip title={i18n.t(stringKey)} overlayStyle={{ fontSize: 12, fontWeight: 500 }}>
          <Badge color={color} />
        </Tooltip>
      );
    }
  };

  getColumns = (): ColumnsType<Incident> => [
    {
      title: i18n.t('incidents.reference'),
      dataIndex: 'id',
      key: 'id',
      render: (_, { id }) => <Highlighter {...getHighlighterProps('#' + id, this.state.searchTerm)} />,
      sorter: true,
      width: '50px',
      ...this.filters.columns['id'],
    },
    {
      key: STATUS,
      align: 'center',
      ellipsis: true,
      render: (_, record: Incident) =>
        record.status !== null ? (
          record.status === InvestigationStatus.OPEN ? (
            <Tooltip title={i18n.t('incidents.open')} overlayStyle={{ fontSize: 12, fontWeight: 500 }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div className="red-dot" />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title={i18n.t('incidents.closed')} overlayStyle={{ fontSize: 12, fontWeight: 500 }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <div className="green-dot" />
              </div>
            </Tooltip>
          )
        ) : (
          i18n.t('dataDisplay.unknownValue')
        ),
      filters: this.statusFilters,
      width: '30px',
      ...this.filters.columns[STATUS],
    },
    {
      title: i18n.t('incidents.eventType'),
      dataIndex: TYPE,
      key: TYPE,
      render: (text, incident) => (
        <Tooltip title={i18n.t(IncidentTypeLabel[text])} overlayStyle={{ fontSize: 12, fontWeight: 500 }}>
          <div>
            {incident.severity && this.getSeverityBadge(incident.severity)}
            <Highlighter {...getHighlighterProps(i18n.t(IncidentTypeLabel[text]), this.state.searchTerm)} />
          </div>
        </Tooltip>
      ),
      ellipsis: true,
      width: '110px',
      filters: this.incidentTypeFilters,
      ...this.filters.columns[TYPE],
    },
    {
      title: i18n.t('incidents.subType'),
      dataIndex: SUB_TYPE,
      key: SUB_TYPE,
      render: (text: any) => (
        <Tooltip title={i18n.t(text)} overlayStyle={{ fontSize: 12, fontWeight: 500 }}>
          <div>
            <Highlighter {...getHighlighterProps(i18n.t(text), this.state.searchTerm)} />
          </div>
        </Tooltip>
      ),
      ellipsis: true,
      width: '110px',
      filters: this.incidentSubTypeFilters,
      ...this.filters.columns[SUB_TYPE],
    },
    {
      title: i18n.t('incidents.reportDate'),
      dataIndex: REPORTED_AT,
      key: REPORTED_AT,
      ellipsis: true,
      width: '120px',
      sorter: true,
      render: (date: string) => (
        <>
          <div style={{ fontSize: '14px' }}>{getDate(date)}</div>
          <div style={{ fontSize: '12px' }}> {getTime(date)}</div>
        </>
      ),
      ...this.filters.columns[REPORTED_AT],
    },

    {
      title: `${i18n.t('shared.company').concat(` & ${i18n.t('shared.division')}`)}`,
      dataIndex: 'company',
      key: ACTION_KEYS.COMPANY,
      render: (_, { company, division }) => (
        <Tooltip
          title={
            <div>
              <HasText
                content={company.name ? company.name : `(${i18n.t('shared.noName', 'no name')})`}
                style={{ color: COLORS.WHITE }}
              />
              <br />
              <HasText content={division.name} style={{ color: COLORS.WHITE }} />
            </div>
          }
          overlayStyle={{ fontSize: 12, fontWeight: 500 }}
        >
          <div>
            <HasText
              content={company.name ? company.name : `(${i18n.t('shared.noName', 'no name')})`}
              style={{ fontSize: '14px' }}
            />
            <br />
            <HasText content={division.name} />
          </div>
        </Tooltip>
      ),
      sorter: true,
      ellipsis: true,
      width: '180px',
      ...this.getFilterAndSorterStatus(ACTION_KEYS.COMPANY),
    },
    {
      title: i18n.t('incidents.reportedBy'),
      dataIndex: 'reportingUser',
      key: 'reportingUser',
      render: (_, { reportingUser }) => (
        <Tooltip title={getUserFullName(reportingUser)} overlayStyle={{ fontSize: 12, fontWeight: 500 }}>
          <div>
            <Highlighter {...getHighlighterProps(getUserFullName(reportingUser), this.state.searchTerm)} />
          </div>
        </Tooltip>
      ),
      sorter: true,
      width: '150px',
      ellipsis: true,
      ...this.filters.columns['reportingUser'],
    },
    {
      title: i18n.t('incidents.subject'),
      dataIndex: 'description',
      key: 'subject',
      ellipsis: true,
      width: '90px',
      render: (text: any) => (
        <Tooltip title={text} overlayStyle={{ fontSize: 12, fontWeight: 500 }}>
          <div>
            <Highlighter {...getHighlighterProps(text, this.state.searchTerm)} />
          </div>
        </Tooltip>
      ),
      ...this.filters.columns['description'],
    },
    {
      title: i18n.t('shared.riskScore', 'Risk score'),
      key: 'risk',
      ellipsis: true,
      width: '100px',
      children: [
        {
          title: i18n.t('incidents.investigation', 'Investigation'),
          dataIndex: 'investigationRiskScore',
          key: 'investigationRiskScore',
          render: (_: any, { investigationRiskLevel, investigationRiskScore }: Incident) => (
            <RiskScoreTag riskScore={investigationRiskScore} riskLevel={investigationRiskLevel} />
          ),
          width: '50px',
          ...this.filters.columns['investigationRiskScore'],
        },
        {
          title: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ paddingRight: '4px' }}>{i18n.t('incidents.action', 'Action')}</div>
              <Tooltip
                title={i18n.t(
                  'incident.actionsRiskScoreTooltip',
                  'This value is an average of the risk scores of actions linked to the event'
                )}
                overlayStyle={{ fontSize: 12, fontWeight: 500 }}
              >
                <AiOutlineInfoCircle size={'16px'} style={{ flexShrink: 0 }} />
              </Tooltip>
            </div>
          ),
          dataIndex: 'actionsRiskScore',
          key: 'actionsRiskScore',
          render: (_: any, record: Incident) => {
            const { actionsRiskScoreAverage } = record;
            if (actionsRiskScoreAverage) {
              const riskScore = EventUtils.mapToClosestRiskMatrixValue(actionsRiskScoreAverage);
              const color = EventUtils.getRiskColor(riskScore);
              return <RiskScoreTag riskScore={riskScore} riskLevel={color ? COLOR_RISK_LEVEL[color] : undefined} />;
            } else {
              return (
                <Tooltip
                  title={i18n.t('riskAssessment.noRiskAssigned', 'No risk level assigned')}
                  overlayStyle={{ fontSize: 12, fontWeight: 500 }}
                >
                  <div>-</div>
                </Tooltip>
              );
            }
          },
          width: '50px',
          ...this.filters.columns['actionsRiskScore'],
        },
      ],
    },
    {
      title: i18n.t('incidents.sif'),
      dataIndex: 'highPotentialEvent',
      key: 'isHighPotentialEvent',
      sorter: true,
      ellipsis: true,
      width: '40px',
      align: 'center',
      render: (_, record: Incident) =>
        record.highPotentialEvent !== null ? (
          <Tooltip
            title={
              record.highPotentialEvent
                ? i18n.t('incidents.highPotentialEvent')
                : i18n.t('incidents.nonHighPotentialEvent')
            }
            overlayStyle={{ fontSize: 12, fontWeight: 500 }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {record.highPotentialEvent ? <div className="red-dot" /> : <div className="green-dot" />}
            </div>
          </Tooltip>
        ) : (
          i18n.t('dataDisplay.unknownValue')
        ),
      filters: this.sifFilters,
      ...this.filters.columns[SIF],
    },
    {
      dataIndex: IS_INVESTIGATION_REQUIRED,
      key: IS_INVESTIGATION_REQUIRED,
      align: 'center',
      render: (_, record: Incident) => (
        <Tooltip
          title={record.investigationRequired ? i18n.t('incidents.investigation') : null}
          overlayStyle={{ fontSize: 12, fontWeight: 500 }}
        >
          {record.investigationRequired ? (
            <AiOutlineFileSearch
              size={'20px'}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.navigateToInvestigation(record.id);
              }}
            />
          ) : undefined}
        </Tooltip>
      ),
      filters: this.investigationFilters,
      sorter: true,
      ellipsis: true,
      width: '40px',
      ...this.filters.columns[IS_INVESTIGATION_REQUIRED],
    },
    {
      key: HAS_ACTIONS,
      align: 'center',
      render: (_, record: Incident) =>
        record.hasActions !== null ? (
          record.hasActions ? (
            <div className="d-flex flex-column align-items-center">
              <Tooltip
                title={
                  record.areAllActionsClosed
                    ? i18n.t('action.allClosed', 'All actions are closed')
                    : i18n.t('action.someOpened', 'Some actions are still opened')
                }
                overlayStyle={{ fontSize: 12, fontWeight: 500 }}
              >
                <FaArrowRight
                  color={record.areAllActionsClosed ? COLORS.GREEN : COLORS.LIGHT_RED}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.navigateToAction(record.id);
                  }}
                />
              </Tooltip>
              <Tooltip
                title={
                  record.areAllComplianceFormsCompleted
                    ? i18n.t('action.regulatoryQuestions.allCompleted', 'All regulatory questions completed')
                    : i18n.t(
                        'action.regulatoryQuestions.someNotCompleted',
                        'Some regulatory questions are not completed'
                      )
                }
                className="mt-1"
                overlayStyle={{ fontSize: 12, fontWeight: 500 }}
              >
                {record.areAllComplianceFormsCompleted ? (
                  <AiOutlineCheckSquare size={'20px'} color={COLORS.GREEN} />
                ) : (
                  <AiOutlineWarning size={'20px'} color={COLORS.LIGHT_RED} />
                )}
              </Tooltip>
            </div>
          ) : undefined
        ) : (
          i18n.t('dataDisplay.unknownValue')
        ),
      filters: this.actionFilters,
      width: '30px',
      ...this.filters.columns[HAS_ACTIONS],
    },
    {
      align: 'center',
      render: (_, record: Incident) => (
        <Tooltip
          title={record.hidden ? i18n.t('incidents.unhide') : i18n.t('incidents.hide')}
          placement="left"
          overlayStyle={{ fontSize: 12, fontWeight: 500 }}
        >
          {!record.hidden ? (
            <EyeOutlined
              onClick={(event) => this.toggleHidden(event, record)}
              style={{
                fontSize: '18px',
                padding: '10px 16px',
                margin: '-10px',
              }}
              className="icon-hover primary"
            />
          ) : (
            <EyeInvisibleOutlined
              onClick={(event) => this.toggleHidden(event, record)}
              style={{
                fontSize: '18px',
                padding: '10px 16px',
                margin: '-10px',
              }}
              className="icon-hover primary"
            />
          )}
        </Tooltip>
      ),
      width: '30px',
    },
  ];

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    if (searchTerm && searchTerm !== '') {
      this.filters.searchTerm = searchTerm;
    } else {
      this.filters.searchTerm = undefined;
    }
    this.filters.pageNumber = 1;
    FiltersService.saveEventTableFilters(this.filters);
    this.getIncidentCountAndList();
  };

  handleChangeDateRange = (dateRange: [Moment | null, Moment | null] | null) => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      this.filters.dateRange = [dateRange[0].format(DB_DATE_FORMAT), dateRange[1].format(DB_DATE_FORMAT)];
    } else {
      this.filters.dateRange = null;
    }
    this.filters.pageNumber = 1;
    FiltersService.saveEventTableFilters(this.filters);
    this.getIncidentCountAndList();
  };

  toggleHidden = (event: React.MouseEvent<HTMLElement, MouseEvent>, record: Incident) => {
    catchClickEvent(event);
    IncidentService.hideIncident(record.id)
      .then(
        () => {
          IncidentService.incidentListChanged();
          this.getIncidentCountAndList();
          Notification.success(SuccessMessages.INCIDENT_HIDING);
        },
        (error) => Notification.error(ErrorMessages.INCIDENT_HIDING(error.response?.data.message))
      )
      .catch((error) => Notification.error(ErrorMessages.INCIDENT_HIDING(error.response?.data.message)));
  };

  toggleShowHiddenEvents = (event: CheckboxChangeEvent) => {
    const shouldShowHidden = event.target.checked;
    this.filters.showHidden = shouldShowHidden;
    FiltersService.saveEventTableFilters(this.filters);
    this.getIncidentCountAndList();
  };

  rowInteraction = (record: Incident) => ({
    onClick: (event: React.MouseEvent) => {
      this.props.navigateToIncident(record.id);
    },
  });

  directExportCSV = () => {
    IncidentService.getDirectCsv(this.filters).then(
      (response) => {
        const url = window.URL.createObjectURL(new Blob(['\uFEFF' + response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${formatTextToTitlecase(i18n.t('incidents.event_plural'))}-${new Date().toLocaleDateString(DATE_LOCALE)}.csv`
        );
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
      },
      (error) => {
        Notification.error(ErrorMessages.CSV_FAIL(error.response?.data.message));
      }
    );
  };

  componentWillUnmount() {
    i18n.off('languageChanged', () => this.setTableFilters());
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Returns if the investigation is overdue or not
   * @param reportedAt - when the investigation was reported
   * @param type - the type of the investigation
   * @returns - boolean value - if the investigation is overdue and should be closed by the number of days specific
   * to the type of the investigation(incident - 15 days, near hit - 5 days, HIRE - 1 day)
   * true - the investigation is overdue
   */
  isOverdue(reportedAt: string, type: string) {
    const date = new Date(reportedAt);

    switch (type) {
      case IncidentType.INCIDENT:
        date.setTime(date.getTime() + 15 * oneDayInMs);
        break;
      case IncidentType.NEAR_HIT:
        date.setTime(date.getTime() + 5 * oneDayInMs);
        break;
      case IncidentType.HOUSE_KEEPING:
        date.setTime(date.getTime() + 1 * oneDayInMs);
        break;
      default:
        break;
    }

    if (date < new Date()) {
      return true;
    }

    return false;
  }

  render() {
    const { exportCsvCallback } = this.props;
    const { data: filteredData, total, loading } = this.state;
    return (
      <>
        <div className="d-flex flex-row justify-content-between mb-3">
          <div>
            <HasSearchInput onSearch={(e) => this.handleSearch(e)} value={this.filters.searchTerm} />
            <DatePicker.RangePicker
              value={
                this.filters.dateRange ? [moment(this.filters.dateRange[0]), moment(this.filters.dateRange[1])] : null
              }
              onChange={(value) => {
                this.handleChangeDateRange(value);
              }}
              style={{ marginRight: '20px', padding: '6px' }}
            />
            <HasCheckbox
              content={i18n.t('incidents.showHidden')}
              disabled={loading}
              checked={this.filters.showHidden}
              onChange={this.toggleShowHiddenEvents}
            />
          </div>
          <div className="d-flex">
            <HasButton
              type="link"
              onClick={this.directExportCSV}
              disabled={loading}
              className="d-flex align-items-center"
            >
              {i18n.t('incidents.directExportCSV')}
            </HasButton>
            <HasButton type="link" onClick={exportCsvCallback} disabled={loading} className="d-flex align-items-center">
              {i18n.t('incidents.exportCSV')}
              <RightOutlined className="align-middle" />
            </HasButton>
          </div>
        </div>
        <HasTable<Incident>
          rowKey="id"
          loading={loading}
          onRow={(record) => this.rowInteraction(record)}
          onChange={this.onTableChange}
          columns={this.getColumns()}
          data={filteredData}
          rowClassName={(record) => (record.hidden ? 'has-hidden-incident' : '')}
          tableItemHeight={TABLE_ITEM_HEIGHT}
          tableHeaderHeight={TABLE_HEADER_HEIGHT}
          tablePaginationHeight={TABLE_PAGINATION_HEIGHT}
          total={total}
          defaultPageNumber={this.filters.pageNumber}
          onPageSizeChange={(size: number) => (this.filters.pageSize = size)}
          size={'small'}
        />
      </>
    );
  }
}

export default HasIncidentsTable;
