import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Popconfirm, Skeleton } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { isEqual } from 'lodash';
import React from 'react';
import { MdDelete } from 'react-icons/md';
import {
  HasButton,
  HasPermissionInviteModal,
  HasTable,
  HasText,
  HasTextInput,
  Notification,
} from '../../../components';
import i18n from '../../../i18n/config';
import { User, UserRole } from '../../../models';
import { EntityAction, UserService } from '../../../services';
import { ConfirmMessages, ErrorMessages, SORT_DIRECTIONS, SuccessMessages } from '../../../shared';
import {
  comparatorFn,
  displayPlaceholderIfEmpty,
  getUserFullName,
  hidePhoneNumber,
  isUserRoleEqualsTo,
} from '../../../utils';

interface PermissionsSettingsProps {
  users: User[];
  loading: boolean;
}

interface PermissionsSettingsState {
  searchTerm: string;
  users: User[];
  showInviteModal: boolean;
}

const TABLE_ITEM_HEIGHT = 55;
const TABLE_HEADER_HEIGHT = 55;

class HasPermissionsSettings extends React.Component<PermissionsSettingsProps, PermissionsSettingsState> {
  state: PermissionsSettingsState = {
    users: this.props.users,
    showInviteModal: false,
    searchTerm: '',
  };

  componentDidUpdate(prevProps: Readonly<PermissionsSettingsProps>, prevState: PermissionsSettingsState) {
    if (!isEqual(prevProps, this.props)) {
      this.setState({ users: this.props.users });
    }
  }

  getColumns = (): ColumnProps<User>[] => [
    {
      title: i18n.t('shared.firstName'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      sortDirections: SORT_DIRECTIONS.BOTH,
      defaultSortOrder: SORT_DIRECTIONS.ASCEND[0],
      sorter: {
        compare: (a: User, b: User) => comparatorFn<string>(a.name.toUpperCase(), b.name.toUpperCase()),
        multiple: 1,
      },
    },
    {
      title: i18n.t('shared.lastName'),
      dataIndex: 'surname',
      key: 'surname',
      align: 'center',
      sortDirections: SORT_DIRECTIONS.BOTH,
      sorter: {
        compare: (a: User, b: User) => comparatorFn<string>(a.surname.toUpperCase(), b.surname.toUpperCase()),
        multiple: 2,
      },
    },
    {
      title: i18n.t('shared.phone'),
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      sortDirections: SORT_DIRECTIONS.BOTH,
      sorter: (a: User, b: User) => comparatorFn<string>(a.phone, b.phone),
      render: (_: any, { phone }: User) => this.formatPhoneNumber(phone),
    },
    {
      title: i18n.t('shared.email'),
      key: 'email',
      align: 'center',
      ellipsis: true,
      render: (_, record: User) => displayPlaceholderIfEmpty(record.email),
      sortDirections: SORT_DIRECTIONS.BOTH,
      sorter: (a: User, b: User) => comparatorFn<string>(a.email, b.email),
    },
    {
      title: i18n.t('shared.company'),
      dataIndex: ['company', 'name'],
      key: 'company',
      align: 'center',
      sortDirections: SORT_DIRECTIONS.BOTH,
      sorter: ({ company: aCompany }: User, { company: bCompany }: User) =>
        comparatorFn<string>(aCompany.name, bCompany.name),
    },
    {
      title: i18n.t('shared.division'),
      dataIndex: ['division', 'name'],
      key: 'department',
      align: 'center',
      sortDirections: SORT_DIRECTIONS.BOTH,
      sorter: ({ division: aDivision }: User, { division: bDivision }: User) =>
        comparatorFn<string>(aDivision.name, bDivision.name),
    },
    {
      title: i18n.t('settings.invitedBy'),
      key: 'invitedBy',
      align: 'center',
      render: (_, { invitedBy }: User) => (invitedBy ? getUserFullName(invitedBy) : '-'),
    },
    {
      title: i18n.t('shared.actions'),
      key: 'actions',
      align: 'center',
      render: (text: any, record: User) => (
        <Popconfirm
          title={ConfirmMessages.REVOKE_PERMISSION(record.name, record.surname)}
          okText={i18n.t('shared.yes')}
          cancelText={i18n.t('shared.no')}
          onConfirm={() => this.revokePermission(record)}
          placement="topLeft"
          style={{ maxWidth: '200px' }}
        >
          <MdDelete style={{ fontSize: '18px' }} className="icon-hover danger" />
        </Popconfirm>
      ),
    },
  ];

  inviteUser = async (email: string): Promise<null> => {
    let formData = new FormData();
    let emails: string[] = [email];
    formData.append('selectedUserList', new Blob([JSON.stringify(emails)], { type: 'application/json' }));
    try {
      await UserService.inviteUserToViewCompany(formData);
      Notification.success(SuccessMessages.PERMISSION_ADDED(email));
      UserService.usersChanged(EntityAction.REFRESH, {} as User);
      this.setState({ showInviteModal: false });
      return new Promise((resolve) => resolve());
    } catch (error) {
      Notification.error(ErrorMessages.PERMISSION_ADDED(error.response?.data.message));
      return new Promise((_, reject) => reject());
    }
  };

  revokePermission = (user: User) => {
    if (user.invitedBy) {
      const { invitedBy } = user;
      UserService.revokeDataViewPermission(user.id, invitedBy.id).then(
        () => {
          Notification.success(
            SuccessMessages.PERMISSION_REVOKED(
              user.name,
              user.surname,
              invitedBy.company.name ? invitedBy.company.name : `(${i18n.t('shared.noName', 'no name')})`
            )
          );
          UserService.usersChanged(EntityAction.REFRESH, {} as User);
        },
        (error) =>
          Notification.error(ErrorMessages.PERMISSION_REVOKED(user.name, user.surname, error.response?.data.message))
      );
    } else {
      Notification.error(ErrorMessages.PERMISSION_REVOKED(user.name, user.surname));
    }
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value.toLocaleLowerCase();
    if (searchTerm === '') {
      this.setState({ searchTerm: '', users: this.props.users });
    } else {
      let filteredUsers = [] as User[];
      this.props.users.forEach((user) => {
        if (
          user.name.toLocaleLowerCase().includes(searchTerm) ||
          user.surname.toLocaleLowerCase().includes(searchTerm) ||
          user.phone?.includes(searchTerm) ||
          user.email?.includes(searchTerm) ||
          (user.company.name || '').toLocaleLowerCase().includes(searchTerm) ||
          user.division.name.toLocaleLowerCase().includes(searchTerm) ||
          user.status.toString().toLocaleLowerCase().includes(searchTerm) ||
          user.role.toString().toLocaleLowerCase().includes(searchTerm)
        ) {
          filteredUsers.push(user);
        }
      });
      this.setState({ searchTerm: searchTerm, users: filteredUsers });
    }
  };

  formatPhoneNumber = (phoneNumber: string | undefined) => {
    if (!phoneNumber) {
      return '-';
    }
    return !isUserRoleEqualsTo(UserRole.OWNER) && !isUserRoleEqualsTo(UserRole.SUPER_ADMIN)
      ? hidePhoneNumber(phoneNumber)
      : phoneNumber;
  };

  render() {
    const { loading } = this.props;
    const { users, showInviteModal } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <HasTextInput
              prefix={<SearchOutlined />}
              placeholder={i18n.t('shared.search')}
              onChange={(e) => this.handleSearch(e)}
              style={{ maxWidth: '180px', marginRight: '20px' }}
              size="large"
            />
            <Skeleton loading={loading} active paragraph={false} title={{ width: '150px' }}>
              <HasText
                content={i18n.t('dataDisplay.displayingResults', { count: users.length })}
                style={{ fontSize: '12px' }}
              />
            </Skeleton>
          </div>
          <HasButton
            onClick={() => this.setState({ showInviteModal: true })}
            type="primary"
            disabled={loading}
            icon={<PlusOutlined />}
            style={{ minWidth: 0 }}
          />
        </div>
        <HasTable<User>
          rowKey={(record: User) => `${record.id} ${record.invitedBy ? record.invitedBy.id : '-'}`}
          data={users}
          columns={this.getColumns()}
          loading={loading}
          tableHeaderHeight={TABLE_HEADER_HEIGHT}
          tableItemHeight={TABLE_ITEM_HEIGHT}
          useScroll
        />
        <HasPermissionInviteModal
          visible={showInviteModal}
          onOk={this.inviteUser}
          onCancel={() => this.setState({ showInviteModal: false })}
        />
      </div>
    );
  }
}

export default HasPermissionsSettings;
