import React from 'react';
import i18n from '../../../i18n/config';
import { Team, User } from '../../../models';
import { TABLE_HEADER_HEIGHT, TABLE_ITEM_HEIGHT } from '../../../utils';
import { HasButton, HasText, HasTitle } from '../../atoms';
import { HasTable } from '../../molecules';

interface HasViewTeamProps {
  team: Team;
  setEditView: (a: boolean) => void;
  setTeamDetailsVisible: (a: boolean) => void;
}

const HasViewTeam: React.FC<HasViewTeamProps> = ({ team, setEditView, setTeamDetailsVisible }) => {
  const columns = [
    {
      title: i18n.t('team.employeeName'),
      key: 'name',
      width: '30%',
      render: (user: User) => `${user?.name} ${user?.surname}`,
      sorter: (a: User, b: User) => a.name.localeCompare(b.name),
    },
    {
      title: i18n.t('shared.phone'),
      key: 'phone',
      width: '30%',
      render: (user: User) => user.phone,
    },
    {
      title: i18n.t('shared.division'),
      key: 'division.name',
      width: '40%',
      render: (user: User) => <HasText content={user.division?.name} />,
      sorter: (a: User, b: User) => a.name.localeCompare(b.name),
    },
  ];

  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-between w-100">
        <HasTitle content={team?.name} level={3} className="mb-0 text-color primary" />
        <div className="d-flex flex-row align-items-center">
          <>
            <HasButton
              type="primary"
              onClick={() => {
                setEditView(true);
              }}
            >
              {i18n.t('team.editTeam')}
            </HasButton>
            <HasButton
              type="default"
              style={{ marginLeft: '16px' }}
              onClick={() => {
                setTeamDetailsVisible(false);
              }}
            >
              {i18n.t('team.close')}
            </HasButton>
          </>
        </div>
      </div>
      <HasTable<User>
        rowKey="id"
        columns={columns}
        data={team?.members}
        style={{ width: '100%', height: '100%' }}
        tableItemHeight={TABLE_ITEM_HEIGHT}
        tableHeaderHeight={TABLE_HEADER_HEIGHT}
      />
    </>
  );
};

export { HasViewTeam };
