import { Table } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import i18n from '../../../i18n/config';
import { User } from '../../../models';
import { Team } from '../../../models/team.model';
import { HasButton, HasText, HasTextInput } from '../../atoms';

interface HasManageTeamProps {
  team: Team | undefined;
  users: User[];
  setNewMembers: (members: User) => void;
  setTeamName: (name: string) => void;
  handleSave: () => void;
  handleExit: () => void;
}

const HasManageTeam: React.FC<HasManageTeamProps> = ({
  team,
  users,
  setNewMembers,
  setTeamName,
  handleSave,
  handleExit,
}) => {
  const [members, setMembers] = useState(team?.members);
  const [isValid, setIsValid] = useState<any>(undefined);

  useEffect(() => setMembers(team?.members), [team]);

  const usersChecked = members
    ? members?.map((user: User) => ({
        ...user,
        key: user?.id,
      }))
    : [];

  const rowSelection: TableRowSelection<User> = {
    onChange: (_, selectedRows: any) => {
      setNewMembers(selectedRows);
      setMembers(selectedRows);
    },
    onSelectAll: (_, selectedRows: any) => {
      setNewMembers(selectedRows);
      setMembers(selectedRows);
    },
    selectedRowKeys: members ? usersChecked?.map((member: User) => member?.id) : [],
  };

  return (
    <>
      <div className="d-flex flex-row align-items-center justify-content-between w-100">
        <HasTextInput
          placeholder={i18n.t('team.enterName')}
          value={team?.name}
          style={{ width: '70%' }}
          onChange={({ target: { value } }) => {
            setTeamName(value);
            setIsValid(value);
          }}
        />
        <div className="d-flex flex-row align-items-center">
          <HasButton
            type="primary"
            style={{ marginLeft: '16px' }}
            onClick={() => (isValid || team?.name) && handleSave()}
            disabled={!isValid && !team?.name}
          >
            {i18n.t('shared.save')}
          </HasButton>
          <HasButton type="default" style={{ marginLeft: '16px' }} onClick={() => handleExit()}>
            {i18n.t('team.exit')}
          </HasButton>
        </div>
      </div>
      <Table<User>
        rowKey="id"
        columns={[
          {
            title: i18n.t('team.employeeName'),
            key: 'name',
            width: '30%',
            render: (user: User) => `${user?.name} ${user?.surname}`,
            sorter: (a: User, b: User) => a.name.localeCompare(b.name),
          },
          {
            title: i18n.t('shared.phone'),
            key: 'phone',
            width: '30%',
            render: (user: User) => user.phone,
          },
          {
            title: i18n.t('shared.division'),
            key: 'division.name',
            width: '40%',
            render: (user: User) => <HasText content={user.division?.name} />,
            sorter: (a: User, b: User) => a.name.localeCompare(b.name),
          },
        ]}
        dataSource={users.map((user: User) => ({ ...user, key: user.id }))}
        rowSelection={rowSelection}
        style={{ width: '100%' }}
      />
    </>
  );
};

export { HasManageTeam };
