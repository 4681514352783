import React, { useEffect, useState } from 'react';
import { HasMap } from '../../../components';
import { ChartCollapsablePanel, ChartInstance, ChartInstanceDataFilter } from '../../../models';
import { ChartService } from '../../../services';
import { TimeIntervals } from '../../../shared';
import { getDatesFromInterval } from '../../../utils';

interface MapChartProps {
  chartInstance: ChartInstance;
  panel: ChartCollapsablePanel;
  selectedCompanies: number[] | undefined;
}

const AbstractMapChart: React.FC<MapChartProps> = ({ chartInstance, panel, selectedCompanies }) => {
  const [values, setValues] = useState<any[]>([]);

  useEffect(() => {
    if (chartInstance.id && selectedCompanies) {
      let chartInstanceDataFilter: ChartInstanceDataFilter = { companies: selectedCompanies };
      if (panel.timeInterval !== TimeIntervals.CUSTOM) {
        const timeInterval = getDatesFromInterval(panel.timeInterval);
        chartInstanceDataFilter.startDate = timeInterval?.startDate;
        chartInstanceDataFilter.endDate = timeInterval?.endDate;
      } else if (panel.startDate && panel.endDate) {
        chartInstanceDataFilter.startDate = panel.startDate;
        chartInstanceDataFilter.endDate = panel.endDate;
      }
      loadChartInstanceData(chartInstance.id, chartInstanceDataFilter);
    }
  }, [selectedCompanies, panel.timeInterval, chartInstance.id, panel.startDate, panel.endDate]);

  const loadChartInstanceData = async (chartInstanceId: number, chartInstanceDataFilter: ChartInstanceDataFilter) => {
    await ChartService.getChartInstanceData(chartInstanceId, chartInstanceDataFilter).then(({ data }) => {
      setValues(data);
    });
  };

  return <HasMap mapId="chart" heatmapLocations={values} height={'calc(100% - 15px)'} />;
};

export default AbstractMapChart;
