import { Divider, Form, TreeSelect } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { isEqual } from 'lodash';
import React from 'react';
import i18n from '../../../i18n/config';
import { Division, User } from '../../../models';
import { ErrorMessages, MISSING_NAME_PLACEHOLDER, ModalBaseProps, Option } from '../../../shared';
import { formatUserRole, getAssignableUserRoles } from '../../../utils';
import { HasDropdown, HasModal, HasText, HasTextInput, Notification } from '../../atoms';

interface UserModalProps extends ModalBaseProps<User> {
  user: User;
  divisions: Division[];
}

interface UserModalState {
  updateInProgress: boolean;
}

class HasEditUserModal extends React.Component<UserModalProps, UserModalState> {
  state = { updateInProgress: false };

  private formRef = React.createRef<FormInstance>();

  private userRole: string = '';
  private userDivision: Division = this.props.user.division;

  componentDidMount() {
    this.props.user.role && (this.userRole = this.props.user.role.toString());
    this.props.user.division && (this.userDivision = this.props.user.division);
  }

  componentDidUpdate(prevProps: Readonly<UserModalProps>) {
    if (!isEqual(prevProps, this.props)) {
      this.props.user.role && (this.userRole = this.props.user.role.toString());
      this.props.user.division && (this.userDivision = this.props.user.division);
    }
  }

  onCancel = () => {
    this.formRef.current?.resetFields();
    this.props.onCancel();
  };

  onOk = (values: any) => {
    this.setState({ updateInProgress: true });
    const user: User = { ...this.props.user, ...values, role: this.userRole, division: this.userDivision };
    this.props.onOk(user).then(
      () => {
        this.formRef.current?.resetFields();
        this.setState({ updateInProgress: false });
      },
      () => this.setState({ updateInProgress: false })
    );
  };

  addChildrenToDivisions = (divisions: Division[]): Array<any> => {
    if (!divisions) {
      return [];
    }
    return divisions
      .filter((division) => !division.deleted)
      .map((division: Division) => ({
        parentId: division.parentId,
        value: division.id,
        label: division.name,
        forObject: division,
        children: division.subDivisions?.length ? this.addChildrenToDivisions(division.subDivisions) : null,
        className: division.name === MISSING_NAME_PLACEHOLDER ? 'italic-text' : '',
      }));
  };

  render() {
    const { user, visible } = this.props;
    const divisionsWithChildren = this.addChildrenToDivisions(this.props.divisions)?.filter(
      (division: Division) => !division.parentId
    );

    return (
      <HasModal
        title={i18n.t('settings.editUser')}
        okText={i18n.t('shared.save')}
        cancelText={i18n.t('shared.cancel')}
        onCancel={this.onCancel}
        closable={false}
        visible={visible}
        okButtonProps={{ loading: this.state.updateInProgress, htmlType: 'submit', form: 'user-edit-form' }}
        cancelButtonProps={{ disabled: this.state.updateInProgress }}
        destroyOnClose
      >
        {user.email && (
          <div style={{ padding: '0px 80px' }}>
            <HasText content={user.email} strong type="secondary" />
            <Divider style={{ margin: '10px 0' }} />
          </div>
        )}
        <Form
          id="user-edit-form"
          ref={this.formRef}
          onFinish={this.onOk}
          onFinishFailed={() =>
            Notification.error(ErrorMessages.INCOMPLETE_FORM_FOR_ENTITY(i18n.t('shared.user').toLowerCase()))
          }
          hideRequiredMark
          layout="vertical"
          style={{ padding: '0px 80px' }}
          initialValues={{
            name: user.name,
            surname: user.surname,
            phone: user.phone,
            email: user.email,
            division: user.division?.id,
            role: user.role?.toString(),
          }}
        >
          <Form.Item
            name="name"
            label={i18n.t('shared.firstName')}
            colon={false}
            rules={[
              { required: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.firstName').toLowerCase()) },
              { whitespace: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.firstName').toLowerCase()) },
            ]}
          >
            <HasTextInput key="name" size="large" />
          </Form.Item>
          <Form.Item
            name="surname"
            label={i18n.t('shared.lastName')}
            colon={false}
            rules={[
              { required: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.lastName').toLowerCase()) },
              { whitespace: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.lastName').toLowerCase()) },
            ]}
          >
            <HasTextInput key="surname" size="large" />
          </Form.Item>
          <Form.Item
            name="phone"
            label={i18n.t('shared.phone')}
            colon={false}
            rules={[
              { required: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.phoneNumber').toLowerCase()) },
              { whitespace: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.phoneNumber').toLowerCase()) },
            ]}
            extra={!user.email && 'Cannot be changed because it is a login credential'}
          >
            <HasTextInput key="phone" size="large" disabled={!user.email} />
          </Form.Item>
          <Form.Item
            name="division"
            label={i18n.t('shared.division')}
            colon={false}
            rules={[{ required: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.division').toLowerCase()) }]}
          >
            <TreeSelect
              showSearch
              treeDefaultExpandAll
              size="large"
              treeNodeFilterProp="label"
              onSelect={(_, option) => (this.userDivision = option.forObject)}
              treeData={divisionsWithChildren}
              placeholder={user.division ? user.division.name : i18n.t('signup.selectDivision')}
              style={{ fontSize: '14px' }}
              listHeight={200}
            />
          </Form.Item>
          <Form.Item
            name="role"
            label={i18n.t('shared.role')}
            colon={false}
            rules={[
              { required: true, message: ErrorMessages.INPUT_REQUIRED(i18n.t('shared.roleForTheUser').toLowerCase()) },
            ]}
            getValueFromEvent={(value: any, option: Option<string>) => {
              this.userRole = option.forObject;
              return formatUserRole(this.userRole);
            }}
          >
            <HasDropdown<string>
              size="large"
              style={{ fontSize: '14px' }}
              options={getAssignableUserRoles().map<Option<string>>((role) => ({
                value: role,
                label: formatUserRole(role),
                forObject: role,
              }))}
            />
          </Form.Item>
        </Form>
      </HasModal>
    );
  }
}

export default HasEditUserModal;
