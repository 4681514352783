import { Popover, Radio, Tag, TreeSelect } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SelectValue } from 'antd/lib/select';
import { isEqual } from 'lodash';
import React, { ReactElement } from 'react';
import { HasMultipleSelectDropdown } from '../..';
import i18n from '../../../i18n/config';
import { Company, Division } from '../../../models';
import { CompanyService } from '../../../services';
import {
  COLORS,
  COMPANY_FILTER,
  CompanyFilterHeader,
  ErrorMessages,
  GroupedOptions,
  INDIVIDUAL_DIVISION_SELECT,
  MISSING_NAME_PLACEHOLDER,
  MultipleDropdownProps,
  Option,
} from '../../../shared';
import { displayPlaceholderIfEmpty, getUserId, updateCompaniesInLocalStorage } from '../../../utils';
import { HasCheckbox, HasText, Toast } from '../../atoms';
import { MdOutlineAccountTree } from 'react-icons/md';
import { IoMdCheckboxOutline } from 'react-icons/io';

interface DropdownGroupProps {
  companies: Company[];
}

interface DropdownGroupState {
  userCompany: Company;
  selectedCompanies: Company[];
  selectedDivisions: Division[];
  companyDropdownOptions: Option<Company>[];
  divisionDropdownOptions: GroupedOptions<Division>[];
  isSelectAllClicked: boolean;
  divisionsOpened: boolean;
  companiesOpened: boolean;
  individualDivisionSelect: boolean;
}

interface SelectAll {
  value: string;
  label: string;
  className?: string;
}

class HasDropdownGroup extends React.Component<DropdownGroupProps, DropdownGroupState> {
  private companyFilter: CompanyFilterHeader = { companies: [] };
  private previousDropdownSelections: { companies: Company[]; divisions: Division[] };

  constructor(props: Readonly<DropdownGroupProps>) {
    super(props);
    const userCompany = this.props.companies.filter((company) => company.isPartnerCompany === false)[0];
    const userCompanyDivisions = userCompany.divisions.filter((division) => !division.deleted);
    const userId = getUserId();

    const filterContent = this.rehydrateFromLocalStorage();

    let selectedCompanies = [];
    let selectedDivisions = [];
    if (filterContent && userId === filterContent.userId) {
      selectedCompanies = filterContent.companies;
      selectedDivisions = filterContent.divisions;
    } else {
      selectedCompanies = [userCompany];
      selectedDivisions = userCompanyDivisions;
    }

    const individualDivisionSelect = localStorage.getItem(INDIVIDUAL_DIVISION_SELECT) === 'true';

    updateCompaniesInLocalStorage(selectedCompanies, selectedDivisions, this.companyFilter, userId!);
    this.previousDropdownSelections = { companies: selectedCompanies, divisions: selectedDivisions };

    this.state = {
      userCompany: userCompany,
      selectedCompanies,
      selectedDivisions,
      companyDropdownOptions: [] as Option<Company>[],
      divisionDropdownOptions: [] as GroupedOptions<Division>[],
      isSelectAllClicked: false,
      divisionsOpened: false,
      companiesOpened: false,
      individualDivisionSelect,
    };
  }

  componentDidMount() {
    const divisionDropdownOptions = this.generateDivisionsOptions(
      this.state.selectedCompanies,
      this.state.selectedDivisions
    );
    // DO NOT REMOVE
    CompanyService.selectedCompanyChanged(999);
    this.setState({
      divisionDropdownOptions: divisionDropdownOptions,
    });
  }

  componentDidUpdate(prevProps: Readonly<DropdownGroupProps>) {
    if (!isEqual(prevProps.companies, this.props.companies)) {
      const uCompanies = this.updateCompanies();
      const uDivisions = this.updateDivisions();
      const userId = getUserId();
      const divisionDropdownOptions = this.generateDivisionsOptions(uCompanies, uDivisions);
      updateCompaniesInLocalStorage(uCompanies, uDivisions, this.companyFilter, userId!);
      this.setState({ selectedCompanies: uCompanies, selectedDivisions: uDivisions, divisionDropdownOptions });
    }
  }

  rehydrateFromLocalStorage = ():
    | { companies: Company[]; divisions: Division[]; userId: number | string | undefined }
    | undefined => {
    const filter = localStorage.getItem(COMPANY_FILTER);
    if (filter) {
      const { companies, userId } = JSON.parse(filter) as CompanyFilterHeader;
      const cIds = companies.map((filter) => filter.cId);
      const dIds = companies
        .map((filter) => filter.dIds)
        .reduce<Array<number>>((acc, idList) => acc.concat(idList ? idList : []), []);
      if (!cIds.length || !dIds.length) {
        return undefined;
      }
      return {
        companies: this.props.companies.filter((c) => cIds.includes(c.id)),
        divisions: dIds?.length
          ? this.props.companies
              .reduce<Division[]>((acc, company) => [...acc, ...company.divisions], [])
              .filter((division) => !division.deleted && dIds.includes(division.id))
          : [],
        userId,
      };
    } else {
      return undefined;
    }
  };

  updateCompanies = (): Company[] => {
    const updatedSelectedCompanies = [...this.state.selectedCompanies].map((company) => {
      const companyFromProps = this.props.companies.find((pCompany) => company.id === pCompany.id);
      return companyFromProps ? { ...companyFromProps } : { ...company };
    });
    return updatedSelectedCompanies;
  };

  updateDivisions = (): Division[] => {
    const selectedCompaniesIds = this.state.selectedCompanies.map((company) => company.id);
    const divisionsFromProps = this.props.companies
      .map((company) => company.divisions)
      .reduce((accumulator, divisionArray) => accumulator.concat(...divisionArray), [])
      .filter((division) => !division.deleted && selectedCompaniesIds.includes(division.companyId));
    let updatedSelectedDivisions = [...this.state.selectedDivisions]
      .filter((division) => divisionsFromProps.find((pDivision) => division.id === pDivision.id))
      .map((division) => {
        const updatedDivision = divisionsFromProps.find((pDivision) => division.id === pDivision.id);
        return updatedDivision ? { ...updatedDivision } : { ...division };
      });
    const selectedDivisionsIds = updatedSelectedDivisions.map((division) => division.id);
    updatedSelectedDivisions = updatedSelectedDivisions.concat(
      divisionsFromProps.filter((division) => selectedDivisionsIds.indexOf(division.id) < 0)
    );
    return updatedSelectedDivisions;
  };

  generateCompanyOptions = (): Option<Company | SelectAll>[] => {
    const getScssClasses = (company: Company): string => {
      let scssClassesString = '';
      if (!company.isPartnerCompany) {
        scssClassesString += 'has-dot';
      }
      if (!company.name) {
        scssClassesString += ' italic-text';
      }
      return scssClassesString;
    };

    const companiesOptions = this.props.companies.map<Option<Company>>((company) => ({
      value: company.id,
      label: company.name ? company.name : `(${i18n.t('shared.noName', 'no name')})`,
      forObject: company,
      className: getScssClasses(company),
    }));
    const allOption: SelectAll = {
      label: 'Select all',
      value: 'all',
    };

    const selectOptions = [
      allOption,
      ...companiesOptions.map((val) => ({
        label: val.label,
        value: val.value,
        forObject: val.forObject,
        className: val.className,
      })),
    ];
    return selectOptions.map<Option<Company>>((option) => ({
      value: option.value,
      label: option.label,
      forObject: (option as Option<Company>).forObject,
      className: option.className,
    }));
  };

  generateDivisionsOptions = (
    selectedCompanies: Company[],
    selectedDivisions: Division[]
  ): GroupedOptions<Division>[] => {
    let divisionDropdownOptionsConfig: GroupedOptions<Division>[] = [];
    const oneCompanySelected = selectedCompanies.length === 1;
    const divisionsForOneCompanySelected = selectedDivisions.every(
      (division) => division.companyId === selectedDivisions[0].companyId
    );
    selectedCompanies.forEach((company) => {
      const groupCheckboxDisabled =
        oneCompanySelected || (divisionsForOneCompanySelected && selectedDivisions[0].companyId === company.id);
      if (company.divisions.length) {
        divisionDropdownOptionsConfig.push({
          label: this.getDivisionGroupLabel(company, selectedDivisions, groupCheckboxDisabled),
          options: company.divisions
            .filter((division) => !division.deleted)
            .map<Option<Division>>((division) => ({
              value: division.id,
              label: displayPlaceholderIfEmpty(division.name, i18n.t('dataDisplay.unnamed')),
              forObject: division,
            })),
        });
      }
    });
    return divisionDropdownOptionsConfig;
  };

  getDivisionGroupLabel = (
    company: Company,
    selectedDivisions: Division[],
    checkboxDisabled: boolean
  ): ReactElement => {
    const allCompanyDivisionsSelected = company.divisions.every(
      (division) => division.deleted || selectedDivisions.indexOf(division) > -1
    );
    const hasAtLeastOneDivisionSelected = selectedDivisions.some((division) => division.companyId === company.id);

    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {allCompanyDivisionsSelected ? (
          <div style={{ color: COLORS.BLACK_RGBA(0.65), fontWeight: 600 }} className="text-truncate">
            {company.name}
          </div>
        ) : (
          <div className="text-truncate">{company.name}</div>
        )}
        <HasCheckbox
          checked={allCompanyDivisionsSelected}
          indeterminate={!allCompanyDivisionsSelected && hasAtLeastOneDivisionSelected}
          disabled={checkboxDisabled}
          onChange={(event: CheckboxChangeEvent) => this.toggleDivisionsForCompany(event.target.checked, company)}
          style={{ padding: 0 }}
        />
      </div>
    );
  };

  toggleDivisionsForCompany = (selectAll: boolean, company: Company) => {
    const newSelectedDivisions = selectAll
      ? this.state.selectedDivisions.concat(
          company.divisions.filter(
            (division) => !division.deleted && !(this.state.selectedDivisions.indexOf(division) > -1)
          )
        )
      : this.state.selectedDivisions.filter((division) => division.companyId !== company.id);

    if (newSelectedDivisions.length === 0) {
      Toast.error(ErrorMessages.AT_LEAST_ONE_SELECTED(i18n.t('shared.division').toLowerCase()));
      return;
    }

    this.updateDropdowns(this.state.selectedCompanies, newSelectedDivisions);
  };

  companySelected = (value: SelectValue, option: Option<Company>) => {
    let companies: Company[];
    let divisions: Division[] = [];
    if (option.value === 'all') {
      if (!this.state.isSelectAllClicked) {
        companies = this.props.companies;
        companies.forEach((company) => {
          company.divisions.forEach((division) => {
            if (!division.deleted) {
              divisions.push(division);
            }
          });
        });
      } else {
        companies = this.state.selectedCompanies.filter((company) => company === this.state.userCompany);
        divisions = this.state.selectedDivisions.filter((division) =>
          this.state.userCompany.divisions.forEach((d) => d.id === division.companyId)
        );
      }
      this.setState({ isSelectAllClicked: !this.state.isSelectAllClicked });
    } else {
      companies = this.state.selectedCompanies.concat(option.forObject as Company);
      divisions = this.state.selectedDivisions.concat(
        option.forObject.divisions.filter((division) => !division.deleted)
      );
    }
    this.updateDropdowns(companies, divisions);
  };

  companyDeselected = (value: SelectValue, option: Option<Company>) => {
    let companies: Company[];
    let divisions: Division[] = [];
    if (this.state.selectedCompanies.length === 1) {
      Toast.error(ErrorMessages.AT_LEAST_ONE_SELECTED(i18n.t('shared.division').toLowerCase()));
      return;
    }
    if (option.value === 'all') {
      companies = this.state.selectedCompanies.filter((company) => company === this.state.userCompany);
      this.state.userCompany.divisions.forEach((division) => divisions.push(division));
    } else {
      companies = this.state.selectedCompanies.filter((company) => company.id !== option.forObject.id);
      divisions = this.state.selectedDivisions.filter((division) => division.companyId !== option.forObject.id);
    }
    this.setState({ isSelectAllClicked: false });
    this.updateDropdowns(companies, divisions);
  };

  inidividualDivisionSelected = (option: any) => {
    const divisions = this.state.selectedDivisions.concat(option.forObject);
    this.updateDropdowns(this.state.selectedCompanies, divisions);
  };

  individualDivisionDeselected = (option: any) => {
    if (this.state.selectedDivisions.length === 1) {
      Toast.error(ErrorMessages.AT_LEAST_ONE_SELECTED(i18n.t('shared.division').toLowerCase()));
      return;
    }
    const divisions = this.state.selectedDivisions.filter((division) => division.id !== option.forObject.id);
    this.updateDropdowns(this.state.selectedCompanies, divisions);
  };

  divisionSelected = (option: any, key: any) => {
    const { selectedDivisions } = this.state;
    const divisionsToAdd = this.getAllSubdivisions(option.forObject);
    this.updateDropdowns(this.state.selectedCompanies, [...selectedDivisions, ...divisionsToAdd]);
  };

  divisionDeselected = (option: any, key: any) => {
    let { selectedDivisions } = this.state;
    const removedDivisions = this.getAllSubdivisions(option.forObject);
    selectedDivisions = selectedDivisions.filter((division) => {
      return !removedDivisions.map((division) => division.id).includes(division.id);
    });
    if (selectedDivisions.length < 1) {
      Toast.error(ErrorMessages.AT_LEAST_ONE_SELECTED(i18n.t('shared.division').toLowerCase()));
      return;
    }
    this.updateDropdowns(this.state.selectedCompanies, selectedDivisions);
  };

  getAllSubdivisions = (rootDivision: Division) => {
    const allSubdivisions: Division[] = [];
    allSubdivisions.push(rootDivision);
    if (rootDivision.subDivisions && rootDivision.subDivisions.length > 0) {
      rootDivision.subDivisions.forEach((subDivision) => {
        allSubdivisions.push(...this.getAllSubdivisions(subDivision));
      });
    }
    return allSubdivisions;
  };

  updateDropdowns = (companies: Company[], divisions: Division[]) => {
    const divisionDropdownOptions = this.generateDivisionsOptions(companies, divisions);
    this.setState({
      selectedCompanies: companies,
      selectedDivisions: divisions,
      divisionDropdownOptions,
    });
  };

  fetchDataIfNecessary = () => {
    if (
      !isEqual(this.state.selectedCompanies, this.previousDropdownSelections.companies) ||
      !isEqual(this.state.selectedDivisions, this.previousDropdownSelections.divisions)
    ) {
      const { selectedCompanies, selectedDivisions } = this.state;
      const userId = getUserId();
      updateCompaniesInLocalStorage(selectedCompanies, selectedDivisions, this.companyFilter, userId!);
      // DO NOT REMOVE
      CompanyService.selectedCompanyChanged(999);
    }
    this.previousDropdownSelections = {
      companies: this.state.selectedCompanies,
      divisions: this.state.selectedDivisions,
    };
  };

  tagsPlaceholder = (omittedValues: any, textToDisplay: string) =>
    omittedValues.length < 2 ? omittedValues[0].label : textToDisplay;

  getDropdownCommonProps = (): Omit<MultipleDropdownProps<any>, 'onChange'> => ({
    mode: 'multiple',
    tagRender: (props: any) => {
      return (
        <HasText
          content={props.label}
          style={{
            verticalAlign: 'middle',
            maxWidth: '160px',
            fontStyle: props.label === `(${i18n.t('shared.noName', 'no name')})` ? 'italic' : '',
          }}
          ellipsis
        />
      );
    },
    maxTagCount: 0,
    showArrow: true,
    showSearch: false,
  });

  addChildrenToDivisions = (divisions: Division[]): Array<any> => {
    if (!divisions) {
      return [];
    }

    return divisions.map((division: Division) => ({
      parentId: division.parentId,
      value: division.id.toString(),
      label: division.name,
      forObject: division,
      children: division.subDivisions?.length ? this.addChildrenToDivisions(division.subDivisions) : null,
      className: division.name === MISSING_NAME_PLACEHOLDER ? 'italic-text' : '',
    }));
  };

  updateDivisionSelectionMode = (checked: boolean) => {
    localStorage.setItem(INDIVIDUAL_DIVISION_SELECT, JSON.stringify(checked));
    this.setState({ individualDivisionSelect: checked });
  };

  render() {
    const { selectedCompanies, selectedDivisions, isSelectAllClicked, divisionDropdownOptions } = this.state;

    const companiesWithChildren = selectedCompanies.map((company) => ({
      value: `C-${company.id.toString()}`,
      label: company.name,
      checkable: false,
      forObject: company,
      selectable: false,
      children: this.addChildrenToDivisions(company.divisions)?.filter((division: Division) => !division.parentId),
    }));

    return (
      <>
        <HasMultipleSelectDropdown<Company>
          {...this.getDropdownCommonProps()}
          showSearch
          optionFilterProp="label"
          value={
            isSelectAllClicked
              ? this.generateCompanyOptions().map((company) => company.value)
              : selectedCompanies.map((company) => company.id)
          }
          options={this.generateCompanyOptions()}
          onSelect={this.companySelected}
          onDeselect={this.companyDeselected}
          onDropdownVisibleChange={(open: boolean) => {
            !open && this.fetchDataIfNecessary();
            this.setState({ companiesOpened: open });
          }}
          maxTagPlaceholder={(omittedValues) =>
            !this.state.companiesOpened
              ? this.tagsPlaceholder(
                  omittedValues,
                  i18n.t('dataDisplay.selectionCount', {
                    count: selectedCompanies.length,
                    entity: i18n.t('sidebar.companies').toLowerCase(),
                  })
                )
              : undefined
          }
          className="has-company-selector"
        />
        <TreeSelect
          multiple
          showArrow
          showSearch
          treeCheckable
          treeCheckStrictly
          treeDefaultExpandAll
          treeNodeFilterProp="label"
          maxTagCount={0}
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
          treeData={companiesWithChildren}
          value={selectedDivisions.map(({ id }) => id.toString())}
          style={{ minWidth: '240px' }}
          onSelect={(value, option) =>
            this.state.individualDivisionSelect
              ? this.inidividualDivisionSelected(option)
              : this.divisionSelected(option, value)
          }
          onDeselect={(value, option) =>
            this.state.individualDivisionSelect
              ? this.individualDivisionDeselected(option)
              : this.divisionDeselected(option, value)
          }
          onDropdownVisibleChange={(open: boolean) => {
            !open && this.fetchDataIfNecessary();
            this.setState({ divisionsOpened: open });
          }}
          maxTagPlaceholder={(omittedValues) =>
            !this.state.divisionsOpened
              ? this.tagsPlaceholder(
                  omittedValues,
                  i18n.t('dataDisplay.selectionCount', {
                    count: selectedDivisions.length,
                    entity: i18n.t('shared.divisions').toLowerCase(),
                  })
                )
              : undefined
          }
          className="has-division-selector"
          tagRender={(props: any) => (
            <HasText content={props.label} style={{ verticalAlign: 'middle', maxWidth: '160px' }} ellipsis />
          )}
        />
        <Popover
          trigger="click"
          title={i18n.t('division.selectMode', 'Select mode')}
          content={
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                marginTop: '4px',
              }}
            >
              <Radio.Group
                buttonStyle="solid"
                onChange={(e) => this.updateDivisionSelectionMode(e.target.value)}
                value={this.state.individualDivisionSelect}
              >
                <Radio.Button className="switch-radio-button" value={false}>
                  {i18n.t('division.treeSelect', 'Tree')}
                </Radio.Button>
                <Radio.Button className="switch-radio-button" value={true}>
                  {i18n.t('division.individualSelect', 'Individual')}
                </Radio.Button>
              </Radio.Group>
            </div>
          }
        >
          {this.state.individualDivisionSelect ? (
            <IoMdCheckboxOutline style={{ fontSize: '24px', padding: '4px', outline: '1px solid rgba(0,0,0,0.25)' }} />
          ) : (
            <MdOutlineAccountTree
              style={{ fontSize: '24px', padding: '4px', outline: '1px solid rgba(0,0,0,0.25)', opacity: '0.65' }}
            />
          )}
        </Popover>
      </>
    );
  }
}

export default HasDropdownGroup;
