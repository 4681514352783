import { useEffect, useMemo } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

type HeatmapProps = {
  locations: google.maps.LatLng[];
  radius: number;
  opacity: number;
};
const Heatmap: React.FC<HeatmapProps> = ({ locations, radius, opacity }) => {
  const map = useMap();
  const visualization = useMapsLibrary('visualization');

  const heatmap = useMemo(() => {
    if (!visualization) return null;

    return new google.maps.visualization.HeatmapLayer({
      radius: radius,
      opacity: opacity,
    });
  }, [visualization, radius, opacity]);

  useEffect(() => {
    if (!heatmap) return;

    heatmap.setData(
      locations.map((location) => {
        return {
          location: new google.maps.LatLng(location.lat(), location.lng()),
          weight: 10,
        };
      })
    );
  }, [heatmap, radius, opacity]);

  useEffect(() => {
    if (!heatmap) return;

    heatmap.setMap(map);

    return () => {
      heatmap.setMap(null);
    };
  }, [heatmap, map]);

  return null;
};

export { Heatmap };
