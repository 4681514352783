import { Typography } from 'antd';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
import { TextProps } from 'antd/lib/typography/Text';
import { TitleProps } from 'antd/lib/typography/Title';
import React from 'react';
import { ComponentContent, MISSING_NAME_PLACEHOLDER } from '../../../shared';

const { Title, Text, Paragraph } = Typography;

interface OwnTitleProps extends TitleProps {
  content: ComponentContent;
}

interface OwnTextProps extends TextProps {
  content: ComponentContent;
}

interface OwnParagraphProps extends ParagraphProps {
  content: ComponentContent;
}

export const HasTitle = (props: OwnTitleProps) => {
  return (
    <Title {...props}>
      {props.content}
      {props.children}
    </Title>
  );
};

export const HasText = (props: OwnTextProps) => {
  return (
    <Text
      {...props}
      className={
        typeof props.content === 'string' && props.content === MISSING_NAME_PLACEHOLDER
          ? 'italic-text'
          : props.className
      }
    >
      {props.content}
      {props.children}
    </Text>
  );
};

export const HasParagraph = (props: OwnParagraphProps) => {
  return (
    <Paragraph {...props}>
      {props.content}
      {props.children}
    </Paragraph>
  );
};
