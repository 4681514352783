import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import i18n from '../../../i18n/config';
import { Company } from '../../../models';
import { COLORS, SORT_DIRECTIONS } from '../../../shared';
import { comparatorFn, displayPlaceholderIfEmpty, catchClickEvent } from '../../../utils';
import { HasButton, HasTag, HasText } from '../../atoms';
import { HasTable } from '../../molecules';

interface CompaniesTableProps {
  companies: Company[];
  statusToggleCallback: (company: Company) => void;
  onRowClick: (company: Company) => void;
}

const TABLE_ITEM_HEIGHT = 65;
const TABLE_HEADER_HEIGHT = 55;

class HasCompaniesTable extends React.Component<CompaniesTableProps, {}> {
  getColumns = (): ColumnProps<Company>[] => [
    {
      title: i18n.t('shared.name'),
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      ellipsis: true,
      sortDirections: SORT_DIRECTIONS.BOTH,
      defaultSortOrder: SORT_DIRECTIONS.ASCEND[0],
      render: (companyName) => {
        return <HasText content={companyName ? companyName : `(${i18n.t('shared.noName', 'no name')})`} />;
      },
      sorter: (a: Company, b: Company) =>
        comparatorFn<string>(a.name ? a.name.toUpperCase() : '', b.name ? b.name.toUpperCase() : ''),
    },
    {
      title: i18n.t('shared.status'),
      key: 'status',
      align: 'center',
      filters: [
        { text: i18n.t('company.active'), value: true },
        { text: i18n.t('company.inactive'), value: false },
      ],
      onFilter: (value: any, { activated }) => activated === value,
      render: (_, { activated }) =>
        activated ? (
          <HasTag color={COLORS.PRIMARY_BLUE} style={{ minWidth: '60px', textAlign: 'center' }}>
            {i18n.t('company.active')}
          </HasTag>
        ) : (
          <HasTag color={COLORS.BLUE_LIGHT} style={{ minWidth: '60px', textAlign: 'center' }}>
            {i18n.t('company.inactive')}
          </HasTag>
        ),
    },
    {
      title: i18n.t('shared.domain'),
      dataIndex: 'domain',
      key: 'domain',
      align: 'center',
      ellipsis: true,
    },
    {
      title: i18n.t('shared.phone'),
      key: 'phone',
      align: 'center',
      ellipsis: true,
      render: (_, { phoneNumber }: Company) => displayPlaceholderIfEmpty(phoneNumber),
    },
    {
      title: i18n.t('shared.address'),
      key: 'address',
      align: 'center',
      ellipsis: true,
      render: (_, { address }: Company) => displayPlaceholderIfEmpty(address),
    },
    {
      title: i18n.t('shared.actions'),
      key: 'actions',
      align: 'center',
      render: (_, company) =>
        company.activated ? (
          <HasButton
            type="link"
            block
            style={{ color: COLORS.ERROR, fontWeight: 600 }}
            onClick={(e) => this.handleActionClick(e, company)}
          >
            {i18n.t('shared.inactivate')}
          </HasButton>
        ) : (
          <HasButton
            type="link"
            block
            style={{ color: COLORS.PRIMARY_BLUE, fontWeight: 600 }}
            onClick={(e) => this.handleActionClick(e, company)}
          >
            {i18n.t('shared.activate')}
          </HasButton>
        ),
    },
  ];

  handleActionClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, company: Company): void => {
    catchClickEvent(e);
    this.props.statusToggleCallback(company);
  };

  render() {
    const { companies, onRowClick } = this.props;
    return (
      <HasTable<Company>
        rowKey="id"
        columns={this.getColumns()}
        data={companies}
        tableHeaderHeight={TABLE_HEADER_HEIGHT}
        tableItemHeight={TABLE_ITEM_HEIGHT}
        useScroll
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => onRowClick(record),
          };
        }}
      />
    );
  }
}

export default HasCompaniesTable;
