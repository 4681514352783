import { LockOutlined, LoginOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Checkbox, Col, Divider, Form, Row, TreeSelect } from 'antd';
import { AlertProps } from 'antd/lib/alert';
import { FormInstance } from 'antd/lib/form';
import React, { ReactNode } from 'react';
import { MdNavigateNext, MdPersonAdd } from 'react-icons/md';
import { HasAlert, HasButton, HasText, HasTextInput } from '../..';
import i18n from '../../../i18n/config';
import { Company, Division, UserRole } from '../../../models';
import { CompanyService, DivisionService, UserService } from '../../../services';
import {
  COLORS,
  ErrorMessages,
  INVITE_SIGN_UP_EMAIL_WITH_DOMAIN_REGEX,
  MISSING_NAME_PLACEHOLDER,
  PASSWORD_REGEX,
  SuccessMessages,
} from '../../../shared';
import { isObjectEmpty } from '../../../utils';
import { HasSpinner, Notification, Toast } from '../../atoms';
import HasTermsAndConditions from './terms.conditions.modal';

interface SignUpProps {
  backToLoginCallback: () => void;
}

interface SignUpState {
  email: string;
  company: Company;
  divisions: any[];
  tempDivisionName: string;
  userRole?: UserRole;
  isGuest: boolean;
  loading: boolean;
  secondStepLoading: boolean;
  activeSignUpStep: number;
  termsAndConditionsVisible: boolean;
  termsAndConditionsChecked: boolean;
  emailCheckOutcome: AlertProps;
  signUpOutcome: AlertProps;
}

const EMAIL_CHECK_STEP = 1;
const USER_DETAILS_STEP = 2;

class HasSignUp extends React.Component<SignUpProps, SignUpState> {
  defaultState: SignUpState = {
    email: '',
    company: {} as Company,
    divisions: [],
    tempDivisionName: '',
    isGuest: false,
    loading: false,
    secondStepLoading: false,
    activeSignUpStep: EMAIL_CHECK_STEP,
    termsAndConditionsVisible: false,
    termsAndConditionsChecked: false,
    emailCheckOutcome: { message: i18n.t('signup.enterWorkEmail'), type: 'info', showIcon: true },
    signUpOutcome: {} as AlertProps,
  };

  state: SignUpState = { ...this.defaultState };

  private formRef = React.createRef<FormInstance>();

  handleEmailCheck = ({ email }: any) => {
    this.setState({ loading: true });
    UserService.checkEmail(email)
      .then(
        () => {
          this.setState({ loading: false, secondStepLoading: true, activeSignUpStep: USER_DETAILS_STEP, email });
          UserService.getInvitedUserRoleByEmail(email)
            .then((response) => this.getCompany(response.data))
            .catch(() => this.getCompany());
        },
        (error) =>
          this.setState({
            loading: false,
            emailCheckOutcome: { message: error.response?.data.message, type: 'error', showIcon: true },
          })
      )
      .catch((error) =>
        this.setState({
          loading: false,
          emailCheckOutcome: { message: error.response?.data.message, type: 'error', showIcon: true },
        })
      );
  };

  getCompany = (userRole?: UserRole) => {
    const companyFetchedCallback = (company?: Company) => {
      if (company) {
        this.setState({ company, isGuest, userRole });
        this.getDivisionsForCompany(company.id);
      } else {
        this.setState({ isGuest, userRole, secondStepLoading: false, company: {} as Company, divisions: [] });
      }
    };
    const { email } = this.state;
    const isGuest = userRole === UserRole.GUEST;
    if (isGuest) {
      CompanyService.getCompanyBySignUpInvitation(email)
        .then(
          ({ data }) => companyFetchedCallback(data),
          (error) => this.setState({ secondStepLoading: false })
        )
        .catch((error) => this.setState({ secondStepLoading: false }));
    } else {
      CompanyService.getCompanyByEmail(email)
        .then(
          ({ data }) => companyFetchedCallback(data),
          (error) => this.setState({ secondStepLoading: false })
        )
        .catch((error) => this.setState({ secondStepLoading: false }));
    }
  };

  getDivisionsForCompany = (companyId: number) => {
    DivisionService.getDivisionsForCompany(companyId)
      .then(
        ({ data: divisions }) => this.setState({ divisions, secondStepLoading: false }),
        (error) => this.setState({ secondStepLoading: false })
      )
      .catch((error) => this.setState({ secondStepLoading: false }));
  };

  openTermsAndConditions = () => {
    this.setState({ termsAndConditionsVisible: true });
  };

  termsAndConditionsAccepted = () => {
    this.formRef.current?.setFieldsValue({ agreement: true });
    this.setState({ termsAndConditionsChecked: true, termsAndConditionsVisible: false });
  };

  termsAndConditionsRejected = () => {
    this.formRef.current?.setFieldsValue({ agreement: false });
    this.setState({ termsAndConditionsChecked: false, termsAndConditionsVisible: false });
  };

  handleSignUp = (values: any) => {
    this.setState({ loading: true });
    const { userRole, company, divisions, email } = this.state;
    const { company: companyName, division, confirmPassword, agreement, ...rest } = values;
    const user = {
      ...rest,
      email,
      role: userRole,
      company: !isObjectEmpty(company) ? company : { name: companyName },
      division: divisions?.length && +division ? divisions.filter((d) => d.id === division)[0] : { name: division },
    };
    UserService.signUp(user)
      .then(
        () => {
          this.setState({ ...this.defaultState });
          Notification.success(SuccessMessages.REGISTER);
          this.props.backToLoginCallback();
        },
        (error) =>
          this.setState({
            loading: false,
            signUpOutcome: { message: error.response?.data.message, type: 'error', showIcon: true },
          })
      )
      .catch((error) =>
        this.setState({
          loading: false,
          signUpOutcome: { message: error.response?.data.message, type: 'error', showIcon: true },
        })
      );
  };

  navigateToLogin = () => {
    this.setState({
      activeSignUpStep: EMAIL_CHECK_STEP,
      email: '',
      loading: false,
      emailCheckOutcome: { message: i18n.t('signup.enterWorkEmail'), type: 'info', showIcon: true },
      signUpOutcome: {} as AlertProps,
    });
    this.props.backToLoginCallback();
  };

  getFormFooter = (): ReactNode => {
    return (
      <Form.Item>
        <Divider style={{ fontSize: '14px', fontWeight: 'normal' }}>
          <HasText content={i18n.t('shared.hasAccount')} />
        </Divider>
        <HasButton
          block
          type="link"
          icon={<LoginOutlined />}
          htmlType="button"
          size="large"
          onClick={this.navigateToLogin}
          className="float-right pr-0"
        >
          {i18n.t('shared.login')}
        </HasButton>
      </Form.Item>
    );
  };

  getFirstSignUpStep = (): ReactNode => {
    const { loading, emailCheckOutcome, email } = this.state;
    return (
      <Form
        onFinish={this.handleEmailCheck}
        hideRequiredMark={true}
        layout={'vertical'}
        initialValues={{ email: email }}
        className="has-login-form"
      >
        {!isObjectEmpty(emailCheckOutcome) && (
          <Form.Item>
            <HasAlert {...emailCheckOutcome} className="has-session-error" />
          </Form.Item>
        )}
        <Form.Item
          name="email"
          label={i18n.t('signup.workEmail')}
          colon={false}
          rules={[
            {
              required: true,
              message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('signup.workEmail').toLowerCase()),
            },
            {
              whitespace: true,
              message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('signup.workEmail').toLowerCase()),
            },
            { pattern: INVITE_SIGN_UP_EMAIL_WITH_DOMAIN_REGEX, message: ErrorMessages.REGISTER_INVALID_EMAIL },
          ]}
        >
          <HasTextInput
            size="large"
            prefix={<UserOutlined style={{ color: COLORS.BLACK_RGBA(0.5) }} />}
            autoComplete="email"
          />
        </Form.Item>
        <Form.Item style={{ paddingTop: '20px' }}>
          <HasButton type="primary" block htmlType="submit" size="large" loading={loading}>
            {i18n.t('signup.continue')}
            <MdNavigateNext style={{ fontSize: '24px', verticalAlign: 'middle', marginBottom: '1px' }} />
          </HasButton>
        </Form.Item>
        {this.getFormFooter()}
      </Form>
    );
  };

  addChildrenToDivisions = (divisions: Division[]): Array<any> => {
    if (!divisions) {
      return [];
    }
    return divisions.map((division: Division) => ({
      parentId: division.parentId,
      value: division.id,
      label: division.name,
      forObject: division,
      children: division.subDivisions?.length ? this.addChildrenToDivisions(division.subDivisions) : null,
      className: division.name === MISSING_NAME_PLACEHOLDER ? 'italic-text' : '',
    }));
  };

  getSecondSignUpStep = (): ReactNode => {
    const { signUpOutcome, loading, secondStepLoading, isGuest, company, divisions, email, termsAndConditionsChecked } =
      this.state;

    const divisionsWithChildren = this.addChildrenToDivisions(this.state.divisions)?.filter(
      (division: Division) => !division.parentId
    );

    return secondStepLoading ? (
      <HasSpinner size="large" />
    ) : (
      <Form
        ref={this.formRef}
        onFinish={this.handleSignUp}
        hideRequiredMark={true}
        initialValues={{
          email: email,
          company: company.name ? company.name : `(${i18n.t('shared.noName', 'no name')})`,
          agreement: termsAndConditionsChecked,
        }}
        layout={'vertical'}
        className="has-login-form"
      >
        {!isObjectEmpty(signUpOutcome) && (
          <Form.Item>
            <HasAlert {...signUpOutcome} className="has-session-error" />
          </Form.Item>
        )}
        <Row>
          <Col span={isGuest ? 24 : 12}>
            <Form.Item
              name="company"
              label={i18n.t('shared.company')}
              colon={false}
              rules={[
                {
                  required: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('shared.company').toLowerCase()),
                },
                {
                  whitespace: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('shared.company').toLowerCase()),
                },
              ]}
            >
              {!isObjectEmpty(company) ? (
                <HasText
                  content={company.name ? company.name : `(${i18n.t('shared.noName', 'no name')})`}
                  className="text-break"
                />
              ) : (
                <HasTextInput size="large" />
              )}
            </Form.Item>
          </Col>
          {!isGuest && (
            <Col span={12} style={{ height: '100%' }}>
              <Form.Item
                name="division"
                label={i18n.t('shared.division')}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('shared.division').toLowerCase()),
                  },
                ]}
              >
                <TreeSelect
                  clearIcon
                  treeData={divisionsWithChildren}
                  style={{ width: '100%' }}
                  placeholder={divisions?.length > 0 ? i18n.t('signup.selectDivision') : i18n.t('signup.addDivision')}
                />
              </Form.Item>
              <Divider style={{ marginBottom: '8px', marginTop: '-12px' }}>
                <HasText
                  content={i18n.t('division.addNewDivision', 'or add one to the list')}
                  style={{ fontSize: '10px' }}
                />
              </Divider>
              <div className="d-flex flex-row pb-2 pt-0">
                <HasTextInput
                  placeholder={i18n.t('signup.divisionName')}
                  value={this.state.tempDivisionName}
                  onChange={(event) => this.setState({ tempDivisionName: event.target.value })}
                  style={{ marginRight: '10px' }}
                />
                <HasButton
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={this.addNewDivision}
                  style={{ minWidth: 0 }}
                />
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="name"
              label={i18n.t('shared.firstName')}
              colon={false}
              rules={[
                {
                  required: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('shared.firstName').toLowerCase()),
                },
                {
                  whitespace: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('shared.firstName').toLowerCase()),
                },
              ]}
            >
              <HasTextInput size="large" autoComplete="given-name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="surname"
              label={i18n.t('shared.lastName')}
              colon={false}
              rules={[
                {
                  required: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('shared.lastName').toLowerCase()),
                },
                {
                  whitespace: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('shared.lastName').toLowerCase()),
                },
              ]}
            >
              <HasTextInput size="large" autoComplete="family-name" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="email"
              label={i18n.t('shared.email')}
              colon={false}
              rules={[
                {
                  required: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('shared.email').toLowerCase()),
                },
                {
                  whitespace: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('shared.email').toLowerCase()),
                },
              ]}
            >
              <HasText content={email} className="text-break" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              label={i18n.t('shared.phone')}
              colon={false}
              rules={[
                {
                  required: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('shared.phone').toLowerCase()),
                },
                {
                  whitespace: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('shared.phone').toLowerCase()),
                },
              ]}
            >
              <HasTextInput size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="password"
              label={i18n.t('shared.password')}
              colon={false}
              rules={[
                {
                  required: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('shared.password').toLowerCase()),
                },
                {
                  whitespace: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('shared.password').toLowerCase()),
                },
                { pattern: PASSWORD_REGEX, message: ErrorMessages.PASSWORD_INVALID },
                { validator: this.passwordFieldChanged },
              ]}
            >
              <HasTextInput
                type="password"
                size="large"
                prefix={<LockOutlined style={{ color: COLORS.BLACK_RGBA(0.5) }} />}
                autoComplete="new-password"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="confirmPassword"
              label={i18n.t('signup.confirmPassword')}
              colon={false}
              rules={[
                {
                  required: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('shared.password').toLowerCase()),
                },
                {
                  whitespace: true,
                  message: ErrorMessages.PERSONAL_INPUT_REQUIRED(i18n.t('shared.password').toLowerCase()),
                },
                { validator: this.passwordMatchValidator, message: ErrorMessages.PASSWORDS_NOT_MATCH },
              ]}
            >
              <HasTextInput
                type="password"
                size="large"
                prefix={<LockOutlined style={{ color: COLORS.BLACK_RGBA(0.5) }} />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(ErrorMessages.TERMS_AND_CONDITIONS_ACCEPT),
            },
          ]}
          getValueFromEvent={(event) => {
            this.setState({ termsAndConditionsChecked: event.target.checked });
            return event.target.checked;
          }}
        >
          <Checkbox>
            {i18n.t('signup.agreeTermsAndConditions')}
            <HasButton type="link" onClick={this.openTermsAndConditions} className="ml-1 p-0">
              {i18n.t('termsConditions.termsAndConditions')}
            </HasButton>
          </Checkbox>
        </Form.Item>
        <Form.Item style={{ paddingTop: '20px' }}>
          <HasButton
            block
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
            icon={<MdPersonAdd style={{ marginRight: '5px', fontSize: '18px' }} />}
          >
            {i18n.t('shared.register')}
          </HasButton>
        </Form.Item>
        {this.getFormFooter()}
      </Form>
    );
  };

  addNewDivision = () => {
    const { tempDivisionName, divisions } = this.state;
    if (!tempDivisionName.trim() || tempDivisionName.trim() === '') {
      Toast.error(i18n.t('signup.validDivisionName'));
    } else if (divisions.some((d) => d.name === tempDivisionName)) {
      Toast.error(i18n.t('signup.duplicateDivisionName'));
    } else {
      const divisionsList = [...divisions];
      divisionsList.push({ name: tempDivisionName, id: tempDivisionName });
      this.setState({ divisions: divisionsList, tempDivisionName: '' });
    }
  };

  passwordFieldChanged = (rule: any, value: any, callback: any) => {
    if (this.formRef.current) {
      if (value && this.formRef.current.isFieldTouched('confirmPassword')) {
        this.formRef.current.validateFields(['confirmPassword']);
      }
      return Promise.reject();
    }
  };

  passwordMatchValidator = (rule: any, value: any, callback: any) => {
    if (this.formRef.current) {
      try {
        const password = this.formRef.current.getFieldValue('password');
        if (value && password !== value) {
          throw new Error(rule.message);
        } else {
          return Promise.reject();
        }
      } catch (err) {
        return Promise.reject(err);
      }
    }
  };

  render() {
    const { activeSignUpStep, termsAndConditionsVisible } = this.state;
    return (
      <React.Fragment>
        <div style={{ maxWidth: '410px' }}>
          {activeSignUpStep === EMAIL_CHECK_STEP ? this.getFirstSignUpStep() : this.getSecondSignUpStep()}
        </div>
        <HasTermsAndConditions
          visible={termsAndConditionsVisible}
          onAccept={this.termsAndConditionsAccepted}
          onReject={this.termsAndConditionsRejected}
        />
      </React.Fragment>
    );
  }
}

export default HasSignUp;
