import React from 'react';
import { MdCheck } from 'react-icons/md';
import { HasButton } from '../..';
import { MapsContext } from '../../../App';
import { ChatBotCustomComponentProps, DEFAULT_MAP_CENTER, Location, ErrorMessages, COLORS } from '../../../shared';
import { HasMap, MapAddressProps } from '../map';
import { Toast } from '../../atoms';

interface ChatBotMapState {
  canChangeLocation: boolean;
  markerPosition: Location;
  searchedAddress: string | undefined;
}

class HasChatBotMap extends React.Component<ChatBotCustomComponentProps, ChatBotMapState> {
  state = {
    canChangeLocation: true,
    markerPosition: this.props.value || { ...DEFAULT_MAP_CENTER },
    searchedAddress: '',
  };

  // TBD how to proceed when error occurs
  handleAddress = (mapAddress: MapAddressProps) => {
    if (this.state.canChangeLocation) {
      if (mapAddress.coords) {
        this.setState({
          markerPosition: mapAddress.coords,
          searchedAddress: mapAddress.address,
        });
      }
    } else {
      Toast.error(ErrorMessages.INCIDENT_REPORT_LOCATION_ALREADY_SAVED);
    }
  };

  saveLocation = () => {
    const { step, triggerNextStep } = this.props;
    const { markerPosition, searchedAddress } = this.state;
    this.setState({ canChangeLocation: false });
    if (step && triggerNextStep) {
      triggerNextStep({ value: { coords: markerPosition, address: searchedAddress }, trigger: step.metadata.trigger });
    }
  };

  render() {
    const { canChangeLocation, markerPosition } = this.state;

    return (
      <MapsContext.Consumer>
        {(scriptLoaded) =>
          scriptLoaded && (
            <div
              style={{ height: '250px', backgroundColor: canChangeLocation ? COLORS.GRAY_LIGHT : COLORS.PRIMARY_BLUE }}
              className="d-flex flex-row flex-grow-1 has-chat-bot-component"
            >
              <div className="d-flex flex-column flex-grow-1">
                <HasMap
                  mapId="chatbot-map"
                  markerLocations={[markerPosition]}
                  withAutocomplete={canChangeLocation}
                  handleAddress={this.handleAddress}
                />
              </div>
              <div className="d-flex">
                {canChangeLocation && (
                  <HasButton
                    type="link"
                    onClick={this.saveLocation}
                    icon={<MdCheck style={{ color: COLORS.SECONDARY_GREEN, fontSize: '20px' }} />}
                    style={{ minWidth: 0, alignSelf: 'center' }}
                  />
                )}
              </div>
            </div>
          )
        }
      </MapsContext.Consumer>
    );
  }
}

export default HasChatBotMap;
