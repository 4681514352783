import React, { CSSProperties, ReactNode, ReactText } from 'react';
import { FormResult, FormResultType, PermissionType } from '../../../models';
import { isEqual, isUndefined } from 'lodash';
import { HasCollapse, HasParagraph, HasText } from '../../atoms';
import { Tooltip } from 'antd';
import { EventUtils, getDate, getFormattedValue, getTime, hasPermissionType } from '../../../utils';
import { COLORS, CollapsePanelWithContent, DELIMITER, FORM_RESULT_KEYS, IGNORED_TYPES } from '../../../shared';
import i18n from '../../../i18n/config';
import { EditOutlined, GlobalOutlined, RightOutlined, RollbackOutlined } from '@ant-design/icons';
import { PermissionsContext } from '../../../context';

interface IncidentFormEntriesProps {
  reportedAt: string;
  oldestEntries: FormResult[];
  latestEntries: FormResult[];
  entry: FormResult;
  translateOptions?: {
    onShowTranslation?: (id?: number) => void;
    translationResult: { [k: number]: string };
    resultIdsTranslated: number[];
  };
  addEntryComponent: any;
  editToggleCallback: any;
}

interface IncidentFormEntriesState {
  editResultKeys: ReactText[];
}

class HasIncidentFormEntries extends React.Component<IncidentFormEntriesProps, IncidentFormEntriesState> {
  state: IncidentFormEntriesState = {
    editResultKeys: [],
  };

  shouldComponentUpdate(nextProps: Readonly<IncidentFormEntriesProps>, nextState: IncidentFormEntriesState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  getEditIcon = (): ReactNode => (
    <EditOutlined
      className={
        this.state.editResultKeys.includes(this.props.entry.key)
          ? 'investigation-entry-edit active'
          : 'investigation-entry-edit'
      }
      style={{ cursor: 'pointer', float: 'right', top: '-22px', position: 'relative' }}
      onClick={() => this.toggleEditMode(this.props.entry!.key)}
    />
  );

  toggleEditMode = (key: ReactText) => {
    const { editResultKeys } = this.state;
    const { editToggleCallback } = this.props;
    if (editToggleCallback(editResultKeys.includes(key))) {
      this.setState((prevState) => {
        return {
          editResultKeys: prevState.editResultKeys.includes(key)
            ? prevState.editResultKeys.filter((i) => i !== key)
            : [...prevState.editResultKeys, key],
        };
      });
    }
  };

  buildEntry = (
    formResults: FormResult[],
    result: FormResult,
    latest: boolean,
    translateOptions?: {
      onShowTranslation?: (id?: number) => void;
      translationResult: { [k: number]: string };
      resultIdsTranslated: number[];
    }
  ) => {
    const date = result.createdAt
      ? getDate(result.createdAt)
      : result.seqNo === 0
      ? getDate(this.props.reportedAt)
      : '-';
    const time = result.createdAt
      ? getTime(result.createdAt)
      : result.seqNo === 0
      ? getTime(this.props.reportedAt)
      : '-';

    const content = (
      <div style={{ fontSize: '12px', color: COLORS.GRAY }}>
        <b>{result.userName}</b> on <b>{date}</b> at <b>{time}</b>
      </div>
    );

    const entryTextStyle: CSSProperties = {
      fontSize: '12px',
      fontWeight: 600,
      color: latest ? COLORS.PRIMARY_BLUE : COLORS.BLACK_RGBA(0.65),
    };

    const historyInfo = <HasParagraph content={content} style={{ color: COLORS.PRIMARY_BLUE, margin: 0 }} />;

    const displayEntry = () => {
      switch (result.type) {
        case FormResultType.REASON:
          const reasonAndSubReason = EventUtils.getFullReasonOrCause(formResults, FormResultType.REASON, result.seqNo);
          const reasonContent = reasonAndSubReason ? reasonAndSubReason.map((item) => i18n.t(item)).join(', ') : '-';
          return (
            <div className="d-flex flex-row">
              <HasText content={reasonContent} style={{ ...entryTextStyle }} className="text-right" />
            </div>
          );
        case FormResultType.CAUSE:
          const causeAndSubCause = EventUtils.getFullReasonOrCause(formResults, FormResultType.CAUSE, result.seqNo);
          const causeContent = causeAndSubCause ? causeAndSubCause.map((item) => i18n.t(item)).join(', ') : '-';
          return (
            <div className="d-flex flex-row">
              <HasText content={causeContent} style={{ ...entryTextStyle }} />
            </div>
          );
        case FormResultType.TEXT:
          let showTranslateButton = true;
          if (
            [FORM_RESULT_KEYS.VICTIM_CONTACT_FIRST_NAME].includes(result.key) ||
            [FORM_RESULT_KEYS.VICTIM_CONTACT_LAST_NAME].includes(result.key)
          ) {
            showTranslateButton = false;
          } else {
            if (!result.value?.toString()) {
              showTranslateButton = false;
            } else {
              showTranslateButton = !i18n.exists(result.value?.toString() || '');
            }
          }

          let isEntryTranslated = false;
          if (result.id && translateOptions) {
            isEntryTranslated = translateOptions.resultIdsTranslated.includes(result.id);
          }
          return (
            <>
              {!EventUtils.keysToNotTranslate.includes(result.key.toString()) && (
                <div style={{ display: 'grid', gridTemplateColumns: '95% 5%' }}>
                  <div className="d-flex flex-row">
                    <HasText
                      content={
                        isEntryTranslated
                          ? result?.id
                            ? isEntryTranslated === true
                              ? translateOptions?.translationResult[result.id]
                              : 'false'
                            : result.value!.toString()
                          : result.value!.toString()
                      }
                      style={{
                        wordBreak: 'break-all',
                        ...entryTextStyle,
                      }}
                    />
                  </div>

                  <div className="d-flex flex-row justify-content-end">
                    {showTranslateButton && !translateOptions?.resultIdsTranslated.includes(result.id!) && (
                      <Tooltip title={i18n.t('incidents.translate')}>
                        <GlobalOutlined
                          onClick={() =>
                            translateOptions?.onShowTranslation && translateOptions?.onShowTranslation(result.id)
                          }
                          className="align-middle"
                          style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                        />
                      </Tooltip>
                    )}
                    {showTranslateButton && translateOptions?.resultIdsTranslated.includes(result.id!) && (
                      <Tooltip title={i18n.t('incidents.showOriginal')}>
                        <RollbackOutlined
                          onClick={() =>
                            translateOptions?.onShowTranslation && translateOptions?.onShowTranslation(result.id)
                          }
                          className="align-middle"
                          style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
              )}

              {EventUtils.keysToNotTranslate.includes(result.key.toString()) && (
                <div className="d-flex flex-row">
                  <HasText content={result.value!.toString()} style={{ ...entryTextStyle }} />
                </div>
              )}
            </>
          );
        case FormResultType.DATE:
          return (
            <div className="d-flex flex-row">
              <HasText
                content={getFormattedValue(result.type, result.value!.toString())}
                style={{ ...entryTextStyle }}
              />
            </div>
          );
        case FormResultType.BOOLEAN:
        case FormResultType.OPTION:
        case FormResultType.OPTION_DESCRIPTION:
          let showTranslateButtonForOptionDescription = true;
          if (!result.extra?.toString()) {
            showTranslateButtonForOptionDescription = false;
          } else {
            showTranslateButtonForOptionDescription = !i18n.exists(result.extra?.toString() || '');
          }

          let isEntryTranslatedForOptionDescription = false;
          if (result.id && translateOptions) {
            isEntryTranslatedForOptionDescription = translateOptions.resultIdsTranslated.includes(result.id);
          }
          return (
            <>
              {result.extra && !EventUtils.keysToNotTranslate.includes(result.key.toString()) && (
                <div className="d-flex flex-column">
                  <HasText
                    content={i18n.t(getFormattedValue(result.type, result.value!.toString()).trim())}
                    style={{ ...entryTextStyle }}
                  />
                  <div className="d-flex flex-row justify-content-between">
                    <HasText
                      content={
                        isEntryTranslatedForOptionDescription
                          ? result?.id
                            ? isEntryTranslatedForOptionDescription === true
                              ? translateOptions?.translationResult[result.id]
                              : 'false'
                            : result.extra!.toString()
                          : result.extra!.toString()
                      }
                      style={{ fontSize: '12px' }}
                    />
                    <div className="d-flex flex-row">
                      {showTranslateButtonForOptionDescription &&
                        !translateOptions?.resultIdsTranslated.includes(result.id!) && (
                          <Tooltip title={i18n.t('incidents.translate')}>
                            <GlobalOutlined
                              onClick={() =>
                                translateOptions?.onShowTranslation && translateOptions?.onShowTranslation(result.id)
                              }
                              className="align-middle"
                              style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                            />
                          </Tooltip>
                        )}
                      {showTranslateButtonForOptionDescription &&
                        translateOptions?.resultIdsTranslated.includes(result.id!) && (
                          <Tooltip title={i18n.t('incidents.showOriginal')}>
                            <RollbackOutlined
                              onClick={() =>
                                translateOptions?.onShowTranslation && translateOptions?.onShowTranslation(result.id)
                              }
                              className="align-middle"
                              style={{ verticalAlign: 'middle', color: 'rgb(59, 121, 182)' }}
                            />
                          </Tooltip>
                        )}
                    </div>
                  </div>
                </div>
              )}
              {(!result.extra || EventUtils.keysToNotTranslate.includes(result.key.toString())) && (
                <div className="d-flex flex-column">
                  <HasText
                    content={i18n.t(getFormattedValue(result.type, result.value!.toString()).trim())}
                    style={{ ...entryTextStyle }}
                  />
                  <HasText content={result.extra} style={{ fontSize: '12px' }} />
                </div>
              )}
            </>
          );
      }
    };
    return (
      <div key={result.id}>
        {historyInfo}
        {result.value === '' || isUndefined(result.value) || result.value === null ? (
          <div className="d-flex flex-row">
            <HasText
              content={'-'}
              style={{ fontSize: '12px', fontWeight: 600, color: COLORS.PRIMARY_BLUE }}
              className="text-right"
            />
          </div>
        ) : (
          <div style={!latest ? { marginBottom: '14px' } : { marginBottom: '0' }}>{displayEntry()}</div>
        )}
      </div>
    );
  };

  buildOlderEntries = (entries: FormResult[]): ReactNode => {
    const entryNodes = entries.map((entry) => {
      if (![FORM_RESULT_KEYS.SUB_REASON, FORM_RESULT_KEYS.SUB_CAUSE].includes(entry.key)) {
        return this.buildEntry(entries, entry, false, this.props.translateOptions);
      }
    });
    const collapsePanelConfigs: CollapsePanelWithContent[] = [
      {
        key: 'incident_old_entries',
        header: (
          <HasText
            content={i18n.t('dataDisplay.moreEntries', {
              count: entries.filter((entry) => !IGNORED_TYPES.includes(entry.type as FormResultType)).length,
            })}
            strong
            style={{ fontSize: '12px' }}
          />
        ),
        content: entryNodes,
        className: 'has-collapse has-collapse-panel',
      },
    ];

    return (
      <HasCollapse
        bordered={false}
        expandIcon={(panelProps: any) => (
          <RightOutlined rotate={panelProps.isActive ? 90 : 0} style={{ verticalAlign: 'middle' }} />
        )}
        panelConfigs={collapsePanelConfigs}
        className="has-collapse"
      />
    );
  };

  render() {
    const { permissions } = this.context;
    const { oldestEntries, latestEntries, entry, translateOptions, addEntryComponent } = this.props;
    const { editResultKeys } = this.state;
    const newEntryComponent = addEntryComponent
      ? React.cloneElement(addEntryComponent, { closeEditModeCallback: () => this.setState({ editResultKeys: [] }) })
      : null;
    return (
      <React.Fragment>
        {hasPermissionType(PermissionType.INCIDENTS_CHANGE_ANSWERS, permissions) &&
          ![
            FORM_RESULT_KEYS.VICTIM_CONTACT_FIRST_NAME,
            FORM_RESULT_KEYS.VICTIM_CONTACT_LAST_NAME,
            FORM_RESULT_KEYS.EQUIPMENT_MACHINERY_OR_TOOLS_SERIAL_NUMBER,
          ].includes(entry.key) &&
          this.getEditIcon()}
        {oldestEntries?.length > 0 && this.buildOlderEntries(oldestEntries)}
        {this.buildEntry(latestEntries, entry, true, translateOptions)}
        {hasPermissionType(PermissionType.INCIDENTS_CHANGE_ANSWERS, permissions) &&
          editResultKeys.includes(entry!.key) &&
          newEntryComponent}
      </React.Fragment>
    );
  }
}
HasIncidentFormEntries.contextType = PermissionsContext;

export default HasIncidentFormEntries;
