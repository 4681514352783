import React from 'react';
import { HasButton, HasDropdown, HasText } from '../../../atoms';
import { Company, Division } from '../../../../models';
import { DivisionService } from '../../../../services';
import i18n from '../../../../i18n/config';
import { isUndefined } from 'lodash';
import { ErrorMessages, MISSING_NAME_PLACEHOLDER, Option } from '../../../../shared';
import { LabeledValue } from 'antd/lib/select';

interface DivisionPickerFormProps {
  onSave: (data: any) => void;
  closeEditModeCallback?: any;
  company: Company;
}

interface DivisionPickerFormState {
  userDivisions: Division[];
  selectedDivision: Division | undefined;
  error: boolean;
}

class HasDivisionPickerForm extends React.Component<DivisionPickerFormProps, DivisionPickerFormState> {
  state = {
    userDivisions: [],
    selectedDivision: undefined,
    error: false,
  };

  async componentDidMount() {
    const { company } = this.props;
    let userDivisions: Division[] = [];
    const { data } = await DivisionService.getDivisionsForCompany(company.id);
    userDivisions = data;
    this.setState({ userDivisions });
  }

  handleSave = () => {
    const { onSave, closeEditModeCallback } = this.props;
    const { selectedDivision } = this.state;
    if (isUndefined(selectedDivision)) {
      this.setState({ error: true });
    } else {
      onSave(selectedDivision);
      closeEditModeCallback && closeEditModeCallback();
    }
  };

  render() {
    const { selectedDivision, userDivisions, error } = this.state;
    return (
      <div className="d-flex justify-content-between mt-2">
        <div className="d-flex flex-column">
          <HasDropdown<Division>
            showSearch
            placeholder={i18n.t('division.picker.placeholder', 'Select a division')}
            defaultValue={selectedDivision ? selectedDivision : undefined}
            options={userDivisions.map((div: Division) => {
              return {
                key: div.id,
                value: div.name,
                label: div.name,
                forObject: div,
                className: div.name === MISSING_NAME_PLACEHOLDER ? 'italic-text' : '',
              };
            })}
            onChange={(_, option) => this.setState({ selectedDivision: option.forObject })}
            style={{ width: '200px' }}
          />
          {error && (
            <HasText
              content={ErrorMessages.INVESTIGATION_ENTRY_FORM_NOT_COMPLETE}
              type="danger"
              style={{ fontSize: '12px' }}
            />
          )}
        </div>
        <HasButton type="primary" size="small" htmlType="button" onClick={this.handleSave} className="align-self-end">
          {i18n.t('shared.save')}
        </HasButton>
      </div>
    );
  }
}

export default HasDivisionPickerForm;
