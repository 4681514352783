import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './App.scss';
import { HasContainer, HasErrorBoundary } from './pages';
import { LIBRARIES } from './shared';
import './shared/styles/has.scss';
import './shared/styles/overwrites.scss';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PermissionsProvider } from './context';
import { APIProvider } from '@vis.gl/react-google-maps';

export const MapsContext = React.createContext<boolean>(false);

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const App = (props: RouteComponentProps & WithTranslation) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  return (
    <HasErrorBoundary showTrace>
      <APIProvider
        apiKey={API_KEY!}
        libraries={LIBRARIES}
        onLoad={() => setScriptLoaded(true)}
        onError={() => setScriptLoaded(false)}
      >
        <MapsContext.Provider value={scriptLoaded}>
          <PermissionsProvider>
            <HasContainer {...props} />
          </PermissionsProvider>
        </MapsContext.Provider>
      </APIProvider>
    </HasErrorBoundary>
  );
};

export default withTranslation()(withRouter(App));
